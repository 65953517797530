import React from "react";
import { Reveal } from "../Hooks/Reveal";
import ScrollEffect from "../Hooks/ScrollEffect"
import { Link } from "react-router-dom";
import './css/assets.css'
import contactSushi from "../components/images/assets/primary/contact-sushi.png"

function RollingBanner() {

  const coinStyles = {
    bottom: '15%',
    left: '-30px',
    width: '20%',
    maxWidth: '150px',
    minWidth: '80px',
    top: '-15%',
    position: 'absolute'
  }


  return (
    <div className="bg-white relative">

      <section className="container mx-auto px-4 py-8  md:py-12 lg:py-12">
     
        <div className="grid grid-cols-12 gap-8">


    
          <div className="
        md:col-start-2 md:col-span-10
        lg:col-start-2 lg:col-span-10
        xl:col-start-3 xl:col-span-8
         sm:col-span-12
         col-span-12
         rounded-xl bg-gradient-to-r from-primary to-primaryDark relative overflow-hidden">
              
            <div>
            <div class="flex flex-row text-md">
                                    <div class="md:basis-2/3 basis-1/1 px-8 py-8">
              <h4 className="text-2xl text-light lg:text-xl leading-tight">
              Start your health journey
Today, with DietLink 
              </h4>
            
              < Link to="/Contact">
                                   <button className="text-dark bg-secondary mt-4 my-auto py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
      Get intouch
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
          fill="none"
          viewBox="0 0 45 45" width="100" height="100"
                                   
          stroke="currentColor">
                                        <path stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="4"
                                            d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29" />
                                   
      
        </svg>
  </button>
              </Link>
                </div>
                <div class="basis-1/3 px-8 relative">
              <div className="top-0 right-0 backgroundImage absolute"
            style={{
              backgroundImage: `url(${contactSushi})`,
              backgroundPosition: 'left bottom',
              width: '110%',
              left: '0',
              bottom: '0'

            }}
            ></div>
                </div>
              </div>
              </div>
          </div>
          
          </div>

      </section>
    </div>
  )

}

export default RollingBanner;