import React from "react";
import TopBanner from "../TopBanner";
import AboutIntro from "../views/About/AboutIntro";
import Values from "../views/About/Values";
import FAQBlock from "../views/Home/FAQSection";

function About() {
  return (
    <>
      <TopBanner header="About Diet Link" />
      <AboutIntro />
      <FAQBlock />
    </>
  );
}

export default About;
