import React from 'react'
import {
  BrowserRouter as Router,
  Switch, 
  Route, 
} from "react-router-dom";
import Header from './components/Header'
import Footer from './components/Footer'
import About from './components/Pages/About'
import Home from './components/Pages/Home'
import Posts from './components/Pages/Posts'
import Post from './components/Pages/Post'
import Cases from './components/Pages/Cases'
import Case from './components/Pages/Case'
import Contact from './components/Pages/Contact'
import ScrollToTop from './Hooks/ScrollToTop'
/*custom pages */
import MentalHealth from './components/Pages/MentalHealth'
import WeightDiet from './components/Pages/WeightDiet'
import Athlete from './components/Pages/Athletes'
import Referral from './components/Pages/Referral'

function App() {

  return (
    <div className="relative min-h-screen overflow-hidden">
           <Router>
        <Header />
        <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
          <About />
          </Route>
          <Route path="/Contact">
          <Contact />
          </Route>
          <Route path="/posts/:pagenumber/:catId">
          <Posts />
          </Route>
          <Route path="/post/:id">
          <Post />
          </Route>
          <Route path="/cases/:pagenumber">
          <Cases />
            </Route>
            <Route path="/MentalHealth">
          <MentalHealth />
            </Route>
            <Route path="/WeightDiet">
          <WeightDiet />
            </Route>
            <Route path="/Athlete">
          <Athlete />
          </Route>
          <Route path="/case/:id">
            <Case /> 
            </Route>
            <Route path="/referral">
            <Referral /> 
           </Route>
          </Switch>
          </ScrollToTop>

      
      <Footer />
      </Router>
    </div>
  );
}
export default App;
