import React, { Component, useState } from "react";
import DatePlan from "./DatePickerContact";

export class DietPlan extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  selectsRange = (e) => {
    this.props.setNewDateRange(e);
    //console.log("date");
  };
  //selectEnd
  state = {
    error: "",
    class: "",
  };

  showErrors = () => {
    this.setState({
      error: "this is required",
      class: "border-2 border-red",
    });
  };

  render() {
    const { values, handleChange } = this.props;

    let nextButton;

    if (values.support_item !== "" && values.support_location !== "") {
      nextButton = (
        <button
          onClick={this.continue}
          class="ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-light hover:text-primary"
        >
          Next Step
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
            fill="none"
            viewBox="0 0 45 45"
            width="100"
            height="100"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
            />
          </svg>
        </button>
      );
    } else {
      nextButton = (
        <button
          onClick={this.showErrors}
          class=" disabled:opacity-50 ml-auto text-dark bg-light mt-2 py-0.2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 ease-in-out"
        >
          Next step
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block h-8 mt-2 w-10 ml-2"
            fill="none"
            viewBox="0 0 45 45"
            width="100"
            height="100"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
            />
          </svg>
        </button>
      );
    }

    return (
      <div className="w-full">
        <div class="flex space-x-5 justify-between mb-1">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          {nextButton}
        </div>
        <div class="grid grid-cols-2 gap-x-4 bg-light p-4 mb-4">
          <h2 class="col-span-2 text-xl">Diet Plan</h2>
          <h3 class="col-span-2 text-lg text-primary">
            Clients funding information
          </h3>
          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <label className="mb-1">Item allocated for dietitian</label>
            <select
              onChange={handleChange("support_item")}
              defaultValue={values.support_item}
              class={`${
                values.support_item ? "" : this.state.class
              } form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              id="grid-state"
            >
              <option>None</option>
              <option>Assistance with daily life (01_760_0128_3_3)</option>
              <option>Improved health and wellbeing (12_025_0128_3_3)</option>
              <option>Improved daily living skills (15_062_0128_3_3)</option>
              <option>
                EITS Improved daily living skills (15_005_0118_1_3)
              </option>
            </select>
            {!values.support_item && this.state.error ? (
              <small className="text-red">This is required</small>
            ) : (
              ""
            )}
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label for="price" className="mb-1">
              Amount allocated for dietitian
            </label>
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">$</span>
              </div>
              <input
                type="number"
                onChange={handleChange("funding_amount_available")}
                defaultValue={values.funding_amount_available}
                name="price"
                id="price"
                className={`
                form-control
                block
                w-full
                px-3
                py-1.5
                pl-7 pr-12
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
                placeholder="0.00"
              />
            </div>
          </div>

          <div class="form-group mb-4 col-span-1 sm:col-span-1">
            <label className="mb-1">Preferred support location</label>
            <select
              onChange={handleChange("support_location")}
              defaultValue={values.support_location}
              class={`
              ${values.support_location ? "" : this.state.class}
                form-control
                block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              id="grid-state"
            >
              <option>None</option>
              <option>Home</option>
              <option>Telehealth</option>
              <option>Clinic (South Coast Medical-Rye)</option>
            </select>
            {!values.support_location && this.state.error ? (
              <small className="text-red">This is required</small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="grid grid-cols-2 gap-x-4 bg-light p-4">
          <h3 class="col-span-2 mb-0 text-lg text-primary">
            Duration of NDIS Plan
          </h3>
          <p className="text-sm col-span-2 text-red">(if consent given)</p>
          <DatePlan selectDate={this.selectsRange} />
        </div>

        <div class="flex space-x-5 justify-between">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          {nextButton}
        </div>
      </div>
    );
  }
}

export default DietPlan;
