import React, { useState } from "react";
import ReferralForm from "../views/Referral/UserForm";
import StepCount from "../views/Referral/StepCount";
import TopBanner from "../TopBanner";

function Referral() {
  const [currentStep, setCurrentSteps] = useState(1);
  const stepCount = 5;

  const stepNav = [];
  for (let i = 0; i < stepCount; i++) {
    stepNav.push(
      <li class="stepper-step">
        <div
          class={`stepper-head hover:bg-white
        ${currentStep === i + 1 ? "" : ""}
        `}
        >
          <span
            class={`stepper-head-icon ${
              currentStep === i + 1
                ? "bg-primary text-light border border-primary scale-150"
                : ""
            }
              ${
                currentStep < i + 1
                  ? "bg-light text-dark border border-primary  scale-50"
                  : ""
              }

              ${currentStep > i + 1 ? "bg-primary" : ""}
              `}
          >
            {i + 1}
          </span>
        </div>
      </li>
    );
  }

  return (
    <>
      <div className="flex bg-white relative">
        <div class="container">
          <div class="grid grid-cols-12 gap-4 p-5 py-32">
            <div class="col-span-12 sm:col-span-12 md:col-span-10 md:col-start-2 lg:col-start-2 lg:col-span-10 xl:col-span-8 xl:col-start-3 relative">
              <h1 class="text-md">Referral Form</h1>
              <p class="text-center text-sm mb-0">
                {currentStep} of {stepCount} steps
              </p>
              <ul class="stepper" data-mdb-stepper="stepper">
                {stepNav}
              </ul>
              <ReferralForm currentSteps={setCurrentSteps} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Referral;
