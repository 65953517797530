import React, { Component, useState } from "react";
import FormUserDetails from "./FormUserDetails";
import AboutConsumer from "./AboutConsumer";
import DietPlan from "./DietPlan";
import Confirm from "./Confirm";
import Submitted from "./Submitted";
import UploadSection from "./UploadSection";
import { axiosSendEmail } from "../../../Hooks/axiosSendEmail";

export class UserForm extends Component {
  url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/awhitepixel/v1/emailData/`;

  //this.props.setCurrentStep(1);

  state = {
    step: 1,
    email: "admin@plaindesignstudio.com",
    subject: "Referral Form",
    consumer_first_name: "",
    consumer_last_name: "",
    consumer_date_of_birth: "",
    //email: "jjs1990creative@gmail.com",
    //phone: "0405782812",
    address: "",
    address2: "",
    contact_method: "",
    state: "",
    areacode: "",
    ndis_number: "",
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    referrer_first_name: "",
    referrer_last_name: "",
    referrer_phone: "",
    referrer_email: "",
    ref_number: "",
    relationship: "",
    support_item: "",
    funding_amount_available: "",
    support_location: "",
    plan_management: "",
    ndis_start_date: "",
    ndis_end_date: "",
    Disability_Diagnosis_and_Medical_History: "",
    consumer_concerns_and_goals: "",
    Social_circumstances: "",
    who_should_be_contacted: "",
    files: null,
    dynamic_data: "",
  };

  //Go next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
    window.scrollTo(0, 0);
    this.props.currentSteps(step + 1);
  };

  //Go back to prev
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
    window.scrollTo(0, 0);
    this.props.currentSteps(step - 1);
  };

  //Go back to prev
  setDOB = (e) => {
    const { consumer_date_of_birth } = this.state;

    const date = new Date(e);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    this.setState({
      consumer_date_of_birth: formattedDate,
    });
  };

  //Add File
  addFile = (e) => {
    const { files } = this.state;
    // this.setState({ files: e }, () => {
    //   console.log(files);
    // });
    console.log(e);
    this.setState({
      files: e,
    });
    //const { date } = this.state;
    //files.push(e);
  };

  //Go back to prev
  setNewDateRange = (e) => {
    // console.log('hey' + e);
    const { ndis_start_date, ndis_end_date } = this.state;

    const date1 = new Date(e[0]);
    const startDate = date1.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    const date2 = new Date(e[1]);
    const endDate = date2.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    this.setState({
      ndis_start_date: startDate,
      ndis_end_date: endDate,
    });
  };

  submitEmail = (e) => {
    const { step, dynamic_data, files } = this.state;
    const filesStr = files;
    this.setState({
      step: step + 1,
    });
    axiosSendEmail(this.url, e, filesStr, dynamic_data);
  };

  //Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  addDynamicData = (e) => {
    this.setState({ dynamic_data: e });
  };

  render() {
    const { step } = this.state;
    const {
      subject,
      email,
      consumer_first_name,
      consumer_last_name,
      consumer_date_of_birth,
      address,
      address2,
      state,
      areacode,
      contact_method,
      contact_name,
      contact_phone,
      contact_email,
      ndis_number,
      referrer_first_name,
      referrer_last_name,
      referrer_phone,
      referrer_email,
      ref_number,
      relationship,
      support_item,
      funding_amount_available,
      support_location,
      plan_management,
      ndis_start_date,
      ndis_end_date,
      Disability_Diagnosis_and_Medical_History,
      consumer_concerns_and_goals,
      Social_circumstances,
      who_should_be_contacted,
      files,
      dynamic_data,
    } = this.state;

    const values = {
      email,
      subject,
      consumer_first_name,
      consumer_last_name,
      consumer_date_of_birth,
      address,
      address2,
      state,
      areacode,
      contact_method,
      contact_name,
      contact_phone,
      contact_email,
      ndis_number,
      referrer_first_name,
      referrer_last_name,
      referrer_phone,
      referrer_email,
      ref_number,
      relationship,
      support_item,
      funding_amount_available,
      support_location,
      plan_management,
      ndis_start_date,
      ndis_end_date,
      Disability_Diagnosis_and_Medical_History,
      consumer_concerns_and_goals,
      Social_circumstances,
      who_should_be_contacted,
      files,
      dynamic_data,
    };

    switch (step) {
      case 1:
        return (
          <>
            <FormUserDetails
              setDOB={this.setDOB}
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 2:
        return (
          <DietPlan
            setNewDateRange={this.setNewDateRange}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 3:
        return (
          <AboutConsumer
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 4:
        return (
          <UploadSection
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            addFile={this.addFile}
            addDynamicData={this.addDynamicData}
          />
        );
      case 5:
        return (
          <Confirm
            prevStep={this.prevStep}
            submitEmail={this.submitEmail}
            values={values}
          />
        );
      case 6:
        return (
          <Submitted
            prevStep={this.prevStep}
            //submitEmail={this.submitEmail}
            values={values}
          />
        );
    }
  }
}

export default UserForm;
