import React from "react";
import { Link } from "react-router-dom";
import { useAxiosGet } from "../../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";
import '../../css/assets.css'
import '../../css/bgImages.css'

function CarouselCard(props) {
  
  const { title, excerpt, featured_media, id, date } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const newdate = new Date(Date.parse(date));
  const day = newdate.toLocaleString('default', { day: '2-digit' });
  const month = newdate.toLocaleString('default', { month: 'short' });
  const year = newdate.toLocaleString('default', { year: 'numeric' });
  const formatteddate = day + ', ' + month + ' ' + year;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post


  if (image.data) {
    FeaturedImage = image.data.media_details.sizes.full.source_url;
  }


  return (


    <div v-for="card in cards mb-8" className="relative flex flex-wrap flex-col overflow-hidden
    md:mx-4
    sm:m-1
    relative
    m-2 bg-light rounded-xl shadow-md w-100 transform transition duration-500 hover:bg-primary hover:text-white">
      
      <div className="w-full h-full relative">
    <div className="w-full h-full top-0 backgroundImage absolute"
            style={{
              backgroundImage: `url(${FeaturedImage})`
            }}
          ></div>
                              <div
            
            className="w-100 top-0 backgroundImage absolute bg-gradient-to-t from-black"
            style={{
              height: "50vh",
            }}
          ></div>
        <div className="px-8 py-4 z-8 relative align-bottom grid gap-1 content-end" style={{height: '250px'}}>

          <p class="text-white outLineText leading-tight align-bottom mb-0">{title.rendered}</p>
          
            < Link to={`/case/${id}`}
      className=""
            >
            <button class="text-light text-xs my-0 mx-0 p-0 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                    View More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="inline-block h-8 w-10 ml-2 group-hover:translate-x-8 transition transition-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                </button>
                            </Link>
            </div>
        </div>
   
        </div>
 
  );
}

export default CarouselCard;
