import React, {useState} from 'react';
import GetCarousel from './CarouselCasestudy/GetCarousel';
import { useAxiosGet } from '../../Hooks/httpRequest';
import { useParallax } from "react-scroll-parallax";
import ScrollEffect from '../../Hooks/ScrollEffect';
import { Link } from "react-router-dom";


function CarouselCases() {



    //const itemsPerPage = 5
    //const [currentPage, setCurrentPage] = useState(1);
  
  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/case_study?per_page=5`
  //const totalQty = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/posts/v1/getpostqty`

  let posts = useAxiosGet(url)
 // let totalItems = useAxiosGet(totalQty)

    let content = null

    if(posts.error){
        content = <div class="pt-24 flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    }

    if(posts.loading){
        content = <div class="pt-24 flex items-center justify-center space-x-2">
        <div class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full" role="status">
          <span class="visually-hidden">L</span>
        </div>
      </div>
    }

    //Get current post

    //const paginate = (pageNumber) => setCurrentPage(pageNumber)
    //console.log(currentPage)


    if(posts.data){
        content = <GetCarousel items={posts} loading={posts.loading} />
        }
    
    return(

      <>

        <div className="w-full relative">
        <div className="grid grid-cols-12 gap-2 py-16">
          <div className="xl:col-span-3 xl:col-start-1 lg:col-span-4 md:col-span-4 sm:col-span-10 sm:col-start-2 col-span-12  md:pl-12  md:py-4 px-16 md:px-0 md:text-left text-center">
          <h3 className="relativez-50 text-primary md:text-2xl lg:text-2xl xl:text-3xl leading-tight font-heading">
          Some of the people we have helped
              </h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="md:col-span-8 lg:col-span-8 xl:col-span-9 col-span-12">
              {content}
            </div>
          </div>
          </div>
            
       </>

    )
}
export default CarouselCases