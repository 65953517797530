import React, {useState} from 'react'
import { useTransition, animated, config, easings } from 'react-spring'
import NavigationMenu from './NavigationMenu'
import DesktopNav from './DesktopNav'
import { default as MenuBurger } from '../images/menu/menu.svg'
import MenuHover from '../../Hooks/MenuHover'
import SidePanel from './SidePanel'

function Navigation() {
  const [showMenu, setShowMenu] = useState(false)
  
  const styleMenu = {
    zIndex: "99999"
  }

    const maskTransitions = useTransition(showMenu, {
      from: { position: 'absolute', opacity: 0, transform:'translateX(-100%)' },
      enter: { opacity: 1, transform:'translateX(0%)'},
      leave: { opacity: 1, transform:'translateX(-100%)'},
      delay: 20,
      config: {
        duration: 1500,
        easing: easings.easeInOutQuart,
      },
      //onRest: () => set(!showMenu),
    })

  if (showMenu === true) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

    const menuTransitions = useTransition(showMenu, {
        from: { opacity: 1, transform:'translateX(-100%)' },
        enter: { opacity: 1, transform:'translateX(-2%)' },
        leave: { opacity: 1, transform:'translateX(-100%)'},
        delay: 0,
        config: {
          duration: 1500,
          easing: easings.easeInOutQuart,
        },
        //onRest: () => set(!showMenu),
      })

  return (
      <>
      <nav className="bg-primary h-100">
       <div className="bg-primary px-4 py-4 h-100">
        <button className="" onClick={() => setShowMenu(!showMenu)}>
       
          <img width="40px" className="bg-primary pt-2 h-100" src={MenuBurger} />
          
            </button>
       </div> 
      </nav>

      { 
            
            maskTransitions(
                (styles, item) => item && 
                <animated.div 
                style={styles}
                    className="fixed bg-secondary top-0 left-0 w-full h-screen z-9000"
                   onClick={() => setShowMenu(false)}
                   >
                </animated.div>
              )

            }
            
            
            { 
            
            menuTransitions(
                (styles, item) => item && 
                <animated.div 
                style={styles} 
                className="fixed bg-white top-0 left-0 w-full h-full z-50"
                >     
            
            <div class="grid grid-cols-12 gap-0 h-full">

            <div className="col-span-6 hidden bg-white content-center md:grid gap-4 content-center">
                    <div className="ml-12">
                      <SidePanel closeMenu={() => setShowMenu(false)} />
                      </div>
                  </div>
                  <div className="md:col-span-6 col-span-12 bg-light content-center grid gap-4 content-center">
                    <div className="ml-12">
                      <NavigationMenu closeMenu={() => setShowMenu(false)} />
                      </div>
                  </div>
             </div>
            
            
             
                </animated.div>
              )

            }

  
      </>
    )
}
export default Navigation
