import React from "react";
import { useAxiosGet } from '../../../Hooks/httpRequest'
import { useParallax } from "react-scroll-parallax";

function PostContent(props) {

  const { title, content, featured_media } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="pt-32 flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="pt-32 flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post
  if (image.data) {
    FeaturedImage = image.data.media_details.sizes.full.source_url;
  }


  const banner = useParallax({
    speed: -10,
  });

  const bannerText = useParallax({
    speed: -10,
  });

  return (

      <div>
      <div ref={banner.ref} className="container-fluid top-0 relative bg-dark z-8">
        <div className="relative col-8 ">
          <div
            
            className="w-100 top-0 background-image absolute text-dark"
            style={{
              backgroundImage: `url(${FeaturedImage})`,
              height: "95vh",
            }}
          ></div>
          <div className="w-100 relative" style={{ height: "95vh" }}>
            <div
              class="flex justify-center items-center"
              style={{ height: "95vh" }}
            >
              <div ref={bannerText.ref}  class="pt-32 text-center">
                <h1 class="text-5xl text-white">{title.rendered}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="bg-white relative z-10 pt-8">
      <div class="container text-dark">
        <div class="grid grid-cols-12 gap-4 mb-8">
          <div class="col-span-12 md:col-span-8 m-2 ">
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: content.rendered }}
            ></div>
            
          </div>
        </div>
        </div>
      </div>
      </div>
  
  );
}

export default PostContent;
