import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "react-spring";
import useMeasure from "react-use-measure";

function Menu(props) {
  const [refHeight, height] = useMeasure();

  // var elHeight = height['height']
  // console.log(elHeight);
  const [subMenuClicked, setSubMenuClicked] = useState(0);

  const toggleIsClicked = () => {
    // 👇️ passed function to setState
    setSubMenuClicked((current) => !current);
    console.log(subMenuClicked);
  };

  const { showSubMenu } = useSpring({
    showSubMenu: subMenuClicked ? 1 : 0,
    from: {
      maxHeight: "0px",
    },
    config: { mass: 5, tension: 2000, friction: 200 },
  });

  return (
    <ul class="list-none text-lg md:text-2xl xl:text-3xl  flex-col md:flex-row m-2  md:font-bold">
      <li className="menulink hidden">
        <Link to="/Cases/1" onClick={props.closeMenu}>
          Case Studies
        </Link>
      </li>

      <li className="menulink text-black text-lg md:text-3xl xl:text-4xl">
        <button
          className="list-none text-lg md:text-2xl xl:text-3xl  flex-col md:flex-row m-2  md:font-bold"
          onClick={toggleIsClicked}
        >
          Services
        </button>

        <animated.div
          onMouseLeave={() => toggleIsClicked(false)}
          ref={refHeight}
          className={subMenuClicked ? "sub-menu" : "sub-menu"}
          style={{
            maxHeight: showSubMenu.to({
              range: [0, 1],
              output: ["0px", "400px"],
            }),
            color: showSubMenu.to({
              range: [0, 1],
              output: ["black", "white"],
            }),
          }}
        >
          <ul class="text-sm">
            <li>
              <Link
                to="/MentalHealth"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-3 group"
              >
                Mental Health & Diet
              </Link>
            </li>

            <li>
              <Link
                to="/WeightDiet"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-3 group"
              >
                Healthy weight
              </Link>
            </li>

            <li>
              <Link
                to="/Athlete"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-3 group"
              >
                Athletes
              </Link>
            </li>
          </ul>
        </animated.div>
      </li>

      <li className="menulink">
        <Link to="/referral" className="" onClick={props.closeMenu}>
          Referral
        </Link>
      </li>

      <li className="menulink">
        <Link to="/about" className="" onClick={props.closeMenu}>
          About
        </Link>
      </li>
      <li className="menulink hidden">
        <Link to="/Posts/1/1" className="" onClick={props.closeMenu}>
          Posts
        </Link>
      </li>
    </ul>
  );
}

export default Menu;
