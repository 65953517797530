import React from 'react';
import TopBanner from '../TopBanner'
import HealthIntro from '../views/HealthyWeightPage/HealthIntro';
import WeightBody from '../views/HealthyWeightPage/WeightBody';



function WeightDiet(){
    return(
        <>
            <TopBanner header="Healthy weight through diet" />
            <HealthIntro />
            <WeightBody />
        </>
    )
}

export default WeightDiet