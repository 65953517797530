import React, { useState, useEffect } from "react";

export const FileUploads = ({ FileArray }) => {
  const [fileUploads, setFileUploads] = useState([]);

  const handleFileRead = async (event) => {
    const file = event;
    //const file_name = file.name;
    //const base64 = await convertBase64(file);
    //addFile
    // addFile(file);
    setFileUploads([...fileUploads, file]);
  };

  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  const addFile = (e) => {
    //const { fileArray } = this.state;
    // fileArray.push(e);
    // this.props.addFile(fileArray);
    // let file = e;
    //let oldArray = fileUploads;
    // console.log(oldArray.length);
    // if (oldArray.length < 1) {
    //   setFileUploads((fileUploads) => [file]);
    // } else {
    //   console.log("more than zero");
    // }
    //setFileUploads((fileUploads) => [...oldArray, file]);
    //setFileUploads((fileUploads) => [...fileUploads, file]);
    // let value = e.target.value;
    // let note_name = e.target.name;
    // let index = id;
    // //noteDetails[index].name
    // // 1. Make a shallow copy of the items
    // let items = [...noteDetails];
    // // 2. Make a shallow copy of the item you want to mutate
    // let item = { ...noteDetails[index] };
    // // 3. Replace the property you're intested in
    // if ("name" === note_name) {
    //   item.name = value;
    // } else if ("body" === note_name) {
    //   item.body = value;
    // }
    // // 4. Put it back into our array. N.B. we *are* mutating the array here,
    // //    but that's why we made a copy first
    // items[index] = item;
    // // 5. Set the state to our new copy
    // setNoteDetails(items);
    // creatNotes(items);
  };

  const handleRemoveFile = (e) => {
    //let items = noteDetails;
    var items = fileUploads;

    items.splice(e, 1);
    //setNoteDetails([items]);
    //setNoteDetails((items) => items);
    // if (indexOf > -1) {
    //   // only splice array when item is found
    //   let newItems = noteDetails.splice(indexOf, 1); // 2nd parameter means remove one item only
    //   noteDetails(newItems);
    // }
    setFileUploads((fileUploads) => [...items]);
  };

  // const removeFile = (index) => {
  //   const { fileArray } = this.state;
  //   console.log(fileArray[index].name);
  //   if (index !== -1) {
  //     fileArray.splice(index, 1);
  //     this.setState({ fileArray: fileArray });
  //   }
  //   addFile(fileArray);
  // };

  let attachments;

  useEffect(() => {
    // console.log(fileUploads);
    FileArray(fileUploads);
  }, [FileArray, fileUploads]);

  if (fileUploads) {
    attachments = fileUploads.map((file, index) => (
      <li
        className=" bg-white rounded px-6 py-2 border-b border-gray-200 w-full mb-2 flex justify-between"
        key={index}
      >
        <span>{file.name}</span>
        <button
          onClick={(e) => handleRemoveFile(index)}
          class="group inline-block border mr-4 bg-red bg-none py-2 text-xs px-4 text-white leading-tight rounded-full hover:bg-red hover:text-light focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Remove File
        </button>
      </li>
    ));
  }

  return (
    <>
      <div class=" form-group mb-4 col-span-2 sm:col-span-2">
        <label
          for="exampleFormControlTextarea1"
          class="form-label inline-block mb-2 text-gray-700"
        >
          Attachment
        </label>
        <ul class="rounded-lg w-100 text-gray-900 list-none m-0 mb-2">
          {attachments}
        </ul>
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full border-1 border border-solid border-gray-300 rounded-lg cursor-pointer bg-white light:hover:bg-white dark:bg-white hover:bg-gray-500 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-light"
        >
          <div class="flex flex-col items-center justify-center pt-3 pb-3">
            <svg
              aria-hidden="true"
              class="w-10 h-10 mb-3 text-dark"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p class="mb-2 text-sm">
              <span class="font-semibold">Click to upload</span>
            </p>
            <p class="text-xs">PDF, PNG, JPG or GIF</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            onChange={(e) => handleFileRead(e.target.files[0])}
          />
        </label>
      </div>
    </>
  );
};
