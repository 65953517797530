import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from 'react-spring'
import athlete from '../../images/assets/primary/athlete.jpg'
import chilli from '../../images/assets/primary/chili.png'
import radish from '../../images/assets/primary/radish.png'
import sushi from '../../images/assets/primary/sushi.png'
import apple from '../../images/assets/primary/apple.png'


function Athletes(props) {
    
    return (
        <>
        <div className="relative bg-light">

        <div className="absolute z-1" style={{ maxWidth: '140px', right: '-40px', top: '-40px' }} >
                                <AnimatedAsset duration={8000} rotate={10} minusrotate={-15} posx={5} minusx={3} posy={-2} minusy={-3} >
              <img src={apple} />
              </AnimatedAsset>
              </div>

              <section className="container relative mx-auto px-4 z-10 md:py-22 lg:py-32 py-12  text-center lg:text-left">

                            <div className="flex flex-col-reverse lg:flex-row">
                            
                            <div className="px-0 py-4 md:px-12 md:py-12 md:basis-2/3 basis-1/1">
                            <h3 class="xl:text-2xl text-xl text-primary leading-tight">
                            We are dietitians for competitive athletes
                        </h3>
                                <p class="mb-8 relative z-10">
                                Our dietitians are qualified Sports Dietitians with experience with meal planning for competitive athletes to helping those wanting to get the best out of their sport and fitness aspirations.                            </p>
                                 
                               
                        </div>
                        <div className="mb-8 lg:mb-0 mx-auto px-0 md:px-12 lg:basis-3/4 md:basis-1/2 basis-1/1 relative">
                            
                        <div className="absolute z-1" style={{ maxWidth: '150px', left: '20px', top: '-60px' }} >
                                <AnimatedAsset duration={20000} rotate={-20} minusrotate={-40} posx={3} minusx={-4} posy={5} minusy={-5} >
              <img src={sushi} />
              </AnimatedAsset>
                            </div>
                            <img className="rounded-xl z-8 relative" src={athlete} />


                            <div className="absolute z-1 hidden xl:block" style={{ maxWidth: '80px', left: '10px', bottom: '-20px' }} >
                                <AnimatedAsset duration={8000} rotate={-40} minusrotate={-50} posx={5} minusx={3} posy={-2} minusy={-3} >
              <img src={chilli} />
              </AnimatedAsset>
              </div>
                            
                            <div className="absolute z-1 block lg:block xl:block" style={{ maxWidth: '100px', right: '20px', bottom: '-20px' }} >
                                <AnimatedAsset duration={15000} rotate={60} minusrotate={50} posx={-8} minusx={8} posy={8} minusy={-15} >
              <img src={radish} />
              </AnimatedAsset>
              </div>
                            
                        </div>
                            </div>
                            
        </section>
    </div>

   

        </>
    );
}

export default Athletes;