import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { useState } from "react";
import RollingBanner from "./RollingBanner";
import Iconhover from "../Hooks/Iconhover";
import { default as australianFlag } from "./images/assets/australian-aboriginal-flag.jpg";
import { default as torresStraitFlag } from "./images/assets/torres-strait-islands-flag.png";
import { default as ANZAED } from "./images/assets/ANZAED-Badge.png";
import { default as DietitionLogo } from "./images/assets/Dietitians_Australia_AN_Logo.png";
import { default as NutritionLogo } from "./images/assets/Dietitians_Australia_APD.png";
import { Link } from "react-router-dom";

function Footer() {
  // const [facebook, facebooktoggle] = useState(true)
  // const { x } = useSpring({
  //   from: {
  //     x: facebook ? 0 : 1,

  //   },
  //   x: facebook ? 1 : 0,
  //   config: { duration: 380,    tension: 300,
  //     friction: 10,
  //   },
  // })

  /*
  const [twitter, twittertoggle] = useState(true)
  const { y } = useSpring({
    from: {
      y: twitter ? 0 : 1,
      
    },
    y: twitter ? 1 : 0,
    config: { duration: 380,    tension: 300,
      friction: 10,
    },
  })

  const [insta, instatoggle] = useState(true)
  const { z } = useSpring({
    from: {
      z: insta ? 0 : 1,
      
    },
    z: insta ? 1 : 0,
    config: { duration: 380,    tension: 300,
      friction: 10,
    },
  })

  const [linkedin, linkedintoggle] = useState(true)
  const { a } = useSpring({
    from: {
      a: linkedin ? 0 : 1,
      
    },
    a: linkedin ? 1 : 0,
    config: { duration: 380,    tension: 300,
      friction: 10,
    },
  })
  */

  return (
    <>
      <RollingBanner props="gradient.false" />

      <footer className="relative text-center lg:text-left bg-light">
        <div class="flex justify-center items-center lg:justify-center p-6 bg-primary">
          <div class="flex justify-center bg-primary text-secondary">
            <a className="hidden" href="">
              <Iconhover className="mr-6 flex justify-center p-2 bg-none rounded-circle rounded-full">
                <svg
                  aria-hidden="true"
                  style={{ width: "15px" }}
                  focusable="false"
                  data-prefix="fab"
                  data-icon="facebook-f"
                  class="w-90"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    class="fill-white"
                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                  ></path>
                </svg>
              </Iconhover>
            </a>

            <a href="https://www.instagram.com/thedietlink/?hl=en">
              <Iconhover class="mr-6 flex justify-center p-2">
                <svg
                  aria-hidden="true"
                  style={{ width: "25px" }}
                  focusable="false"
                  data-prefix="fab"
                  data-icon="instagram"
                  class="w-100"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  alt="instagram Icon"
                >
                  <path
                    class="fill-white"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </Iconhover>
            </a>

            <a href="https://au.linkedin.com/in/jodiereadapd">
              <Iconhover class="mr-6 flex justify-center p-2">
                <svg
                  aria-hidden="true"
                  style={{ width: "25px" }}
                  focusable="false"
                  data-prefix="fab"
                  data-icon="linkedin-in"
                  class="w-90"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    class="fill-white"
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  ></path>
                </svg>
              </Iconhover>
            </a>
          </div>
        </div>
        <div class="mx-6 py-4 text-center md:text-left text-dark ">
          <div class="grid grid-1 grid-cols-12 pt-12 pb-12 gap-8">
            <div class="lg:col-span-4 lg:col-start-0 md:col-span-8 md:col-start-3 col-span-12">
              <h5 class="text-primary mb-4 flex justify-center md:justify-start">
                Mornington Peninsula
              </h5>
              <p class="flex items-center justify-center md:justify-start mb-4">
                Online Telehealth & Mobile Home Visits clall
              </p>
              <p class="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  class="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    className="fill-primary"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                  ></path>
                </svg>
                <a href="mailto:info@dietlink.com.au">info@dietlink.com.au</a>
              </p>
              <p class="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="phone"
                  class="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    className="fill-primary"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                  ></path>
                </svg>
                <a href="tel:0419587949">0419587949</a>
              </p>
            </div>

            <div class="lg:col-span-4 lg:col-start-0 md:col-span-8 md:col-start-3 col-span-12">
              <h5 class="text-primary mb-4 flex justify-center md:justify-start">
                South Coast Medical
              </h5>
              <p class="flex items-center justify-center md:justify-start mb-4">
                Contact booking consultations in the centre (Wed only):
              </p>
              <p class="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  class="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    className="fill-primary"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                  ></path>
                </svg>
                <a href="mailto:info@dietlink.com.au">info@dietlink.com.au</a>
              </p>
              <p class="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="phone"
                  class="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    className="fill-primary"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                  ></path>
                </svg>
                <a href="tel:59857776">598 577 76</a>
              </p>
            </div>

            <div class="lg:col-span-4 lg:col-start-0 md:col-span-8 md:col-start-3 col-span-12">
              <h5 class="text-primary mb-4 flex justify-center md:justify-start">
                Consultations
              </h5>
              <p class="flex items-center justify-center md:justify-start mb-4">
                MORNINGTON PENINSULA Online Telehealth & Mobile Home Visits
              </p>
              <Link to={`/contact`}>
                <button class="text-white bg-primary mt-2 my-auto py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-primary hover:text-light">
                  Book a consultation
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
                    fill="none"
                    viewBox="0 0 45 45"
                    width="100"
                    height="100"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div class="bg-white text-center md:text-left py-8">
          <div className="container">
            <div class="flex flex-wrap justify-center">
              <img
                style={{ height: "50px" }}
                src={australianFlag}
                class="h-auto mx-2 rounded shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"
                alt="aboriginal flag"
              />
              <img
                style={{ height: "50px" }}
                src={torresStraitFlag}
                class="h-auto mx-2 rounded shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"
                alt="Torres Strait Island Flag"
              />
            </div>
            <div class="grid grid-12 lg:grid-cols-12 pt-4 pb-2 gap-8">
              <div class=" md:col-span-10 md:col-start-2 text-center">
                <h6 class="mb-4 text-primary">Acknowledgement to Country</h6>
                <p class="mb-4 text-sm">
                  In the spirit of reconciliation Diet Link acknowledges and
                  pays respect to the Bunurong people, the Traditional
                  Custodians and their connections to land, sea and community.
                  We pay our respect to their Elders past and present and extend
                  that respect to all Aboriginal and Torres Strait Islander
                  peoples today.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-light text-center md:text-left py-4">
          <div className="container">
            <div class="flex flex-wrap justify-center">
              <div class="flex flex-wrap justify-center w-full">
                <img
                  style={{ height: "80px" }}
                  src={DietitionLogo}
                  class="h-auto mx-2 rounded shadow-none"
                  alt="Dietitians Australia AN Logo"
                />
                <img
                  style={{ height: "80px" }}
                  src={NutritionLogo}
                  class="h-auto mx-2 rounded shadow-none"
                  alt="Biutrition Australia AN Logo"
                />
                <img
                  style={{ height: "80px" }}
                  src={ANZAED}
                  class="h-auto mx-2 rounded shadow-none"
                  alt="ANZAED Icon"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
