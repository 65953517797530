import React, { useState} from 'react';
import { useAxiosGet } from './httpRequest';
import { Link } from "react-router-dom";
// UPDATE this path to your copy of the hook!
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
// import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';

function CategoryLoop (props){

    const loop = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/categories`

    const [categories, setCategory] = useState(false);

    let items = useAxiosGet(loop)

    let content = null;

    function handleChange(event) {
        // Here, we invoke the callback with the new value
        props.onChange(event);
      }

    if (items.error) {
        content = (
          <div class="flex items-center justify-center space-x-2">
            <h2>Opps, Looks like there is an error</h2>
          </div>
        );
      }
    
      if (items.loading) {
        content = (
          <div class="flex items-center justify-center space-x-2">
            <div
              class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
              role="status"
            >
              <span class="visually-hidden">L</span>
            </div>
          </div>
        );
      }

    
    if (items.data) {
        console.log(items.data)
        content = (

            <>
                
                <ul class="bg-white m-4 list-none w-full text-gray-900">
                <h3>Categories</h3>
                    {items.data.map(item => (
         <li className="hover:transition-all hover:translate-x-3 group w-full px-4 py-2">
                    <Link  className="text-dark group-hover:scale-150 transition transition-500" onClick={() => {
                        handleChange(item.id);
                      }} to={`/posts/1/${item.id}`}>
                           {item.name}
                    </ Link>
                    </li>
                ))}
                 </ul>
</>

        );
      }

    return (
        <>
            
            {content}
            
        </>
    );
}

export default CategoryLoop;