import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from 'react-spring'
import healthyWeight from '../../images/assets/primary/healthy-weight.jpg'
import broccoli from '../../images/assets/primary/broccoli.png'
import pickle from '../../images/assets/primary/pickle.png'
import kiwi from '../../images/assets/primary/kiwi.png'

function HealthIntro(props) {

    return (
        <>
            <div className="relative bg-primary">
            <div className="absolute md:d-block d-none z-1" style={{ maxWidth: '150px', left: '-50px', top: '-40px' }} >
                                    <AnimatedAsset duration={15000} rotate={15} minusrotate={25} posx={-8} minusx={8} posy={8} minusy={-15} >
                  <img src={kiwi} />
                  </AnimatedAsset>
                </div>
                
              <section className="container-fluid mx-auto">
                <div className="">

                            <div className="grid grid-cols-12 gap-8">
                            <div className="
                            col-span-12
                            md:col-span-8
                            md:col-start-1
                            lg:col-span-7 lg:col-start-2 py-32 md:py-32 md:px-12 px-8 relative">
                                    <ScrollEffect speed={-3}>
                        <h2 class="text-4xl mb-5 text-white leading-tight">
                        Nutritional advice for a healthy weight
                                        </h2>
                                        <p className="text-white">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed quam sed mauris faucibus egestas a quis ligula. Pellentesque interdum, urna sed hendrerit tincidunt, ligula felis congue ipsum, at mattis enim orci quis velit. Vivamus efficitur tincidunt nulla eu maximus. In ac elit nulla. Suspendisse fermentum volutpat cursus. Integer sed consectetur arcu. Cras placerat interdum tellus ac euismod. Praesent sed sapien eu ex condimentum laoreet non id nulla. Donec ut lacinia nunc. Curabitur ac ipsum ac tellus volutpat malesuada. Quisque volutpat mattis malesuada.
                                            </p>
                                    </ScrollEffect>
                                    
                                </div>
                                
                            <div class="md:col-span-4
                     
                                sm:col-span-2
                                col-span-4
                                relative overflow-hidden">
              <div classsName="relative w-100 h-screen top-0">
              <div
            
            className="clipMask w-100 top-0 right-0 bg-secondary h-screen backgroundImage absolute"
                                    ></div>

<div className="absolute md:d-block d-none z-1 h-64" style={{ right: '-50px', bottom: '40%' }} >
<ScrollEffect speed={-8}>
                                    <img className="rounded-xl z-8 relative h-100" src={healthyWeight} />
  </ScrollEffect>
                            </div>
                                    
                                 
              </div>
            </div>
                </div>

        </div>
                </section>
                <div className="absolute md:d-block d-none z-1" style={{ maxWidth: '200px', right: '-50px', bottom: '-40px' }} >
                                    <AnimatedAsset duration={15000} rotate={30} minusrotate={35} posx={-8} minusx={8} posy={8} minusy={-15} >
                  <img src={broccoli} />
                  </AnimatedAsset>
                            </div>
                </div>

            </>
    );
}

export default HealthIntro;