import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DatePickerContact(props) {
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;

  // let selectHandleDate = () => props.selectsRange(dateRange);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //const [openCalendar, setOpenCalendar] = useState(null);

  //const handleCalendarClose = () => props.handleChange('date');
  const handleStartCalendarOpen = () => {};

  const handleEndCalendarOpen = (e) => {
    // if (openCalendar === null) {
    //   setOpenCalendar(true);
    //   console.log('clicked');
    // }
  };

  //Handle Date
  const selectHandleStartDate = (e) => {
    if (startDate == null || endDate == null) {
    } else {
      //setOpenCalendar(null);
      props.selectDate([startDate, endDate]);
    }
  };

  // let selectHandleStartDate = (e) => {
  //   props.selectEnd([startDate, endDate]);
  // };

  return (
    <>
      <div class="form-group mb-4 col-span-2 sm:col-span-1">
        <label className="mb-1">Start of Plan</label>
        <DatePicker
          className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
          selected={startDate}
          onChange={(startDate) => setStartDate(startDate)}
          onCalendarClose={selectHandleStartDate}
          onCalendarOpen={handleStartCalendarOpen()}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={endDate}
        />
      </div>
      <div class="form-group mb-4 col-span-2 sm:col-span-1">
        <label className="mb-1">End of Plan</label>
        <DatePicker
          className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
          selected={endDate}
          onChange={(endDate) => setEndDate(endDate)}
          onCalendarClose={selectHandleStartDate}
          onCalendarOpen={handleEndCalendarOpen()}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          //open={openCalendar}
          minDate={startDate}
        />
      </div>
    </>
  );
}

export default DatePickerContact;
