import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { Link } from "react-router-dom";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from "react-spring";
import mentalHealth from "../../images/assets/primary/mental-health.jpg";
import banana from "../../images/assets/primary/banana.png";
import lemon from "../../images/assets/primary/lemon.png";
import aboutUs from "../../images/assets/about-us.jpg";
import fullLemon from "../../images/assets/primary/avacado.png";

function AboutIntro() {
  return (
    <>
      <section className="container relative mx-auto px-4 z-10 md:py-12 lg:py-12 py-12  text-center lg:text-left">
        <div
          className="absolute md:d-block d-none z-1"
          style={{ maxWidth: "250px", left: "-150px", top: "-40px" }}
        >
          <AnimatedAsset
            duration={15000}
            rotate={-50}
            minusrotate={25}
            posx={-8}
            minusx={8}
            posy={8}
            minusy={-15}
          >
            <img src={fullLemon} />
          </AnimatedAsset>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="mb-8 lg:mb-0 mx-auto px-0 md:px-12 lg:basis-1/3 md:basis-1/3 sm:basis-1/3 max-w-lg relative">
            <img className="rounded-xl z-8 relative" src={aboutUs} />
          </div>

          <div className="px-0 py-4 md:px-2 md:py-12 lg:basis-2/3 md:basis-1/2 basis-1/1">
            <ScrollEffect speed={-8}>
              <h3 class="md:text-2xl text-xl leading-tight">Our Story</h3>
              <p class="mb-8 relative z-10">
                Since 2010, our founder Jodie Read has guided clients towards
                their health goals. Making sense on nutrition related health
                issues or concerns, and reducing the difficulties that come with
                these.
              </p>
              <p class="mb-8 relative z-10">
                Jodie makes everything easy to understand, relevant to the
                client with enjoyment in life always a priority. Based in Rye,
                Jodie services the Mornington Peninsula in clinic or mobile to
                your home, but can also provide Telehealth consultations to
                anyone in the world.
              </p>

              <p class="mb-8 relative z-10">
                Jodie makes everything easy to understand, relevant to the
                client with enjoyment in life always a priority. Based in Rye,
                Jodie services the Mornington Peninsula in clinic or mobile to
                your home, but can also provide Telehealth consultations to
                anyone in the world.
              </p>
            </ScrollEffect>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutIntro;
