import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from 'react-spring'
import mentalHealth from '../../images/assets/primary/mental-health.jpg'
import banana from '../../images/assets/primary/banana.png'
import lemon from '../../images/assets/primary/lemon.png'
import tomato from '../../images/assets/primary/tomato.png'
import fullLemon from '../../images/assets/primary/full-lemon.png'

//fullLemon


function MentalHealth(props) {
    
    return (
        <>
        <div className="relative bg-content">

        <div className="absolute md:d-block d-none z-1" style={{ maxWidth: '150px', left: '-50px', top: '-40px' }} >
                                    <AnimatedAsset duration={15000} rotate={15} minusrotate={25} posx={-8} minusx={8} posy={8} minusy={-15} >
                  <img src={fullLemon} />
                  </AnimatedAsset>
                            </div>
                            

                            <section className="container relative mx-auto px-4 z-10 md:py-22 lg:py-32 py-12  text-center lg:text-left">

                    
        
                    <div className="flex flex-col lg:flex-row">
                        
                    <div className="mb-8 lg:mb-0 mx-auto px-0 md:px-12 lg:basis-3/4 md:basis-1/2 basis-1/1 relative">
                            
                                
                            
                            <div className="absolute z-1" style={{ maxWidth: '100px', right: '2px', top: '-40px' }} >
                                    <AnimatedAsset duration={20000} rotate={40} minusrotate={-30} posx={3} minusx={-4} posy={5} minusy={-20} >
                  <img src={lemon} />
                  </AnimatedAsset>
                  </div>
                                <img className="rounded-xl z-8 relative" src={mentalHealth} />
                
                <div className="absolute z-10 hidden md:block xl:block" style={{ maxWidth: '220px', left: '-10px', bottom: '-10px' }} >
                                    <AnimatedAsset duration={15000} rotate={40} minusrotate={20} posx={0} minusx={-10} posy={-2} minusy={5} >
                  <img src={banana} />
                  </AnimatedAsset>
                            </div>
                            
                            <div className="absolute z-1 block md:hidden xl:block" style={{ maxWidth: '150px', minWidth: '80px', width: '20%', right: '-40px', bottom: '-40px' }} >
                                    <AnimatedAsset duration={8000} rotate={20} minusrotate={40} posx={3} minusx={-4} posy={5} minusy={-20} >
                  <img src={tomato} />
                  </AnimatedAsset>
                            </div>
                                
                            </div>
                            
                            <div className="px-0 py-4 md:px-2 md:py-12 lg:basis-2/3 md:basis-1/2 basis-1/1">
                        <h3 class="md:text-2xl text-xl text-primary leading-tight">Diet & the connection
to mental health</h3>
                            <p class="mb-8 relative z-10">
                            Mental Health Nutrition is an emerging field of nutrition, dietetic and psychological sciences that explores the changes that nutritional interventions can make to one’s mental health.
                            </p>
                                 
                               
                        </div>
           
                            </div>
                            
        </section>
    </div>

   

        </>
    );
}

export default MentalHealth;