import React from 'react'
import logoBg from "../images/logo/logo-full.svg"
import { Link } from 'react-router-dom';

function SidePanel(props){
  return (

      <div>
            <div class="mx-6 py-10 text-center md:text-left text-dark ">
          <div class="grid grid-1 lg:grid-cols-4 pt-12 pb-12 gap-8">
            
                  <div class="col-span-4 mx-auto">
                      <img width="150" src={logoBg}></img>
        <h5 class="text-primary mb-4 text-2xl flex justify-center md:justify-start">
        Consultations
              </h5>
              <p class="flex items-center justify-center md:justify-start mb-4">
              Mornington Peninsula Online Telehealth & Mobile Home Visits
              </p>

                      < Link to="/Contact" onClick={props.closeMenu}>
              <button class="text-white bg-primary mt-2 my-auto py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-primary hover:text-light">
              Book a consultation
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
          fill="none"
          viewBox="0 0 45 45" width="100" height="100"
                                   
          stroke="currentColor">
                                        <path stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="4"
                                            d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29" />
                                   
      
        </svg>
                          </button>
                          </ Link>
      </div>
    </div>
  </div>
    </div>
)

}


export default SidePanel;