import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring'
// UPDATE this path to your copy of the hook!
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
// import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';
export const Reveal = (props) => {

    const hiddenRef = useRef();

    const [revealed, setReveal] = useState(false);

    useEffect(() => {
        
        window.addEventListener('scroll', scrollHandler);
     
        return () => window.removeEventListener('scroll', scrollHandler);
        
    }, []);
    
    const scrollHandler = () => {

        if (window.pageYOffset + (window.innerHeight / 8 * 6) >= hiddenRef.current.offsetTop) {
            setReveal(true);
        } else {
            setReveal(false);
        }
    }

    const { revealStyle } = useSpring({
        revealStyle: revealed ? 1 : 0,
    from: {
        revealStyle: 0,
        },
        config: config.molasses

  })

    return (
        <>
            <animated.div
                ref={hiddenRef}
                style={{
                        opacity: revealStyle.to({
                            range: [0, 1],
                            output: ['0', '1'],
                        }),
                        translateY: revealStyle.to({
                            range: [0, 1],
                            output: ['80px', '0px'],
                        }),
                }}
            >

            {props.children}
            </animated.div>
        </>
    );
}
