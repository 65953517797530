import React, { useEffect, useState} from 'react'
import Navigation from './Navigation/Navigation'
import { Link } from 'react-router-dom'
import { default as logo } from './images/logo/logo-icon.svg'
import { default as logoText } from './images/logo/logo-text.svg'

function Header() {

     // storing this to get the scroll direction
  const [lastScrollTop, setLastScrollTop] = useState(0);
   // the offset of the document.body
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
   // the vertical direction
  const [scrollY, setScrollY] = useState(bodyOffset.top);
   // the horizontal direction
  const [scrollX, setScrollX] = useState(bodyOffset.left);
   // scroll direction would be either up or down
    const [scrollDirection, setScrollDirection] = useState();
    let colorChange = 'bg-none'
    let logotext = 'regular'


  const listener = e => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };
    
    if (scrollY >  20 ) {
        colorChange = 'bg-secondary';
        logotext = 'textNone'
    }
    if (scrollY < 50 || scrollY === 0) {
        colorChange = 'bg-light';
        logotext = 'regular'
    }

  useEffect(() => {

      window.addEventListener("scroll", listener);
    return () => {

        window.removeEventListener("scroll", listener);
    };
  });
    
  const styles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s",
      background: "white",
    },
    hidden: {
      //visibility: "hidden",
      //background: "bg-primary",
      transition: "all 0.5s",
      transform: "translateY(-90%)"
    }
  }

  const logoStyles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s",
    },
    hidden: {
      //visibility: "hidden",
      //background: "bg-primary",
      transition: "all 0.5s",
      transform: "translateX(-90%)",
    }
  }

  const logoIconStyles = {
    active: {
      visibility: "visible",
      transition: "all 0.8s",
    },
    hidden: {
      //visibility: "hidden",
      //background: "bg-primary",
      transition: "all 0.8s",
      transform: "translateX(-90%) translateY(-90%) rotate(-180deg)",
    }
  }
    
    return (
        <header
            style={scrollDirection === "down" || scrollY <= 10 ? styles.active: styles.hidden} className={`z-9999 flex justify-between fixed z-20 w-full shadow-md ${colorChange}`}>
            <span className="font-bold flex items-center justify-center">
                
          <Link className={`flex left-0 top-0`} style={scrollDirection === "down" || scrollY <= 10 ? logoStyles.active: logoStyles.hidden}  to="/">
            <div className="flex p-2" style={{ height: '80px', width: '80px' }}>
              <img  className="" src={logo} style={scrollDirection === "down" || scrollY <= 10 ? logoIconStyles.active: logoIconStyles.hidden} /> 
              <img  className="" src={logoText} />
            </div>    
                <div className={`relative logoTypeWrapper mt-3`}>
                    <span className={`font-thin text-white logoType relative align-middle ${logotext}`}></span>
                </div>
                    </Link>
                </span>
            
            <Navigation />
        </header>
        
    )
}


export default Header