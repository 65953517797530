import React from "react";
import NutritionalAdvice from "../views/Home/NutritionalAdvice";
import MentalHealth from "../views/Home/MentalHealth";
import FAQBlock from "../views/Home/FAQSection";
//MentalHealth
import TopBanner from "../views/Home/TopBanner";
import HomeIntro from "../views/Home/HomeIntro";
import CarouselCases from "../views/CarouselCases";
import Athletes from "../views/Home/Athletes";
import Priceplan from "../views/Home/Priceplan";
//import ParallaxCache from "./ParallaxCache";
//Athletes
// import BottomBanner from "../BottomBanner"
//import { useParams } from 'react-router-dom';
//import HelloWorld from '../HelloWorld'

export default function Home() {
  const svgStyleTop = {
    position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const svgStyleBottom = {
    position: "absolute",
    color: "white",
    width: "100%",
    bottom: "-1px",
  };

  return (
    <div>
      <TopBanner />

      <HomeIntro />

      <div className="hidden">
        <CarouselCases />
      </div>

      <NutritionalAdvice />

      <MentalHealth />
      <Athletes />
      <FAQBlock />
      <Priceplan />
    </div>
  );
}

//export default Home;
