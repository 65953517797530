import React from "react";
import UserForm from "../views/Contact/UserForm";
import TopBanner from '../TopBanner'

function Contact() {


  return (

    <>
    <div className="flex bg-light relative">
      <div class="container">
          <div class="grid grid-cols-12 gap-4 p-5 py-32">
          <div class="col-span-12 sm:col-span-12 md:col-span-8 md:col-start-3 lg:col-span-6 xl:col-span-6 relative">
          <h1 class="text-dark mb-0 xl:text-5xl lg:text-3xl md:text-2xl text-xl ">Make your dietary change today.</h1>
            <UserForm />
            </div>
            
            <div class="col-span-12 sm:col-span-12 md:col-span-8 md:col-start-3 lg:col-span-5 xl:col-span-5 relative  lg:px-12 px-0 py-12">
              <h4 class="text-dark mb-0">Contact us for your diet</h4>
              <p>Please send us your enquiry</p>
              <p>Referrals from your treating health professional can be emailed to info@dietlink.com.au</p>
              <p>Contact the clinic rooms directly for booking consultations in the centre:</p>

<p>South Coast Medical - Rye P 5985 7776    F 5985 7819</p>
            </div>
            
          </div>
          
      </div>
      </div>
      </>
   
  );
}

export default Contact;
