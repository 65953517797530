import React, { Component } from "react";

export class Confirm extends Component {
  emailData = {
    email: this.props.values.email,
    subject:
      "Referral form for: " +
      this.props.values.consumer_first_name +
      " " +
      this.props.values.consumer_last_name,
    files: this.props.values.files,
  };

  emailBody = {
    //First_name: this.props.values.phone,
    //step: 1,
    name_of_consumer:
      this.props.values.consumer_first_name +
      " " +
      this.props.values.consumer_last_name,
    consumer_date_Of_Birth: this.props.values.consumer_date_of_birth,
    //email: "jjs1990creative@gmail.com",
    //phone: "0405782812",

    consumer_Address:
      this.props.values.address +
      ", " +
      this.props.values.address2 +
      ", " +
      this.props.values.areacode +
      ", " +
      this.props.values.state,

    NDIS_Number: this.props.values.NDIS_Number,
    //ndisnum: "1234",
    contact_method: this.props.values.contact_method,
    contact_name:
      "main contact is the " +
      this.props.values.contact_method +
      ", " +
      this.props.values.contact_name,
    //carername: "Jane Caring",
    main_contact_phone: this.props.values.contact_phone,
    //carerphone: "0405 123 456",
    main_contact_email: this.props.values.contact_email,
    //careremail: "carer@care.com.au",
    name_of_Referrer:
      this.props.values.referrer_first_name +
      " " +
      this.props.values.referrer_last_name,

    referrer_Phone: this.props.values.referrer_phone,
    //phone: this.props.values.phone,
    referrer_Email: this.props.values.referrer_email,
    ref_Number: this.props.values.ref_number,
    funding_amount_available: "$" + this.props.values.funding_amount_available,
    support_location: this.props.values.support_location,
    plan_management: this.props.values.plan_management,
    NDIS_start_date: this.props.values.ndis_start_date,
    NDIS_end_date: this.props.values.ndis_end_date,

    disability_diagnosis_and_medical_history:
      this.props.values.Disability_Diagnosis_and_Medical_History,
    //Disability_Diagnosis_and_Medical_History,
    consumer_concerns_and_goals: this.props.values.consumer_concerns_and_goals,
    //consumer_concerns_and_goals,
    social_circumstances: this.props.values.Social_circumstances,
    //Social_circumstances,
    who_should_be_contacted: this.props.values.who_should_be_contacted,
    //who_should_be_contacted,
  };

  submit = (e) => {
    e.preventDefault();
    //Process Form
    this.props.submitEmail([this.emailBody, this.emailData]);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    let contactPhone;
    let contactEmail;
    if (this.props.values.contact_phone) {
      contactPhone = " phone: " + this.props.values.contact_phone;
    }
    if (this.props.values.contact_phone) {
      contactEmail = " email: " + this.props.values.contact_email;
    }

    // const { step } = this.email_itmes;

    return (
      <div>
        <p className="text-md">
          This is a referral form for {this.emailBody.name_of_consumer}, the{" "}
          {this.emailBody.contact_name} contact them by {contactEmail}
          {contactPhone}
        </p>
        <div class="justify-center"></div>
        <div class="flex space-x-5 justify-between">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          <button
            onClick={this.submit}
            class="ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-light hover:text-primary"
          >
            Send Referral
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
              fill="none"
              viewBox="0 0 45 45"
              width="100"
              height="100"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

export default Confirm;
