import React from 'react';
import TopBanner from '../TopBanner'
import HealthIntro from '../views/AthletePage/AthleteIntro';
import WeightBody from '../views/AthletePage/AthleteBody';



function Athletes(){
    return(
        <>
            <TopBanner header="Athletes" />
            <HealthIntro />
            <WeightBody />
        </>
    )
}

export default Athletes