import React, { useState, component } from "react";
import { Link } from "react-router-dom";

function PaginationNav(props) {
  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange(event);
  }

  const currentPage = parseInt(props.currentPage);
  const totalItems = props.totalItems;
  const itemsPerPage = props.itemsPerPage;
  const pageNumbers = [];

  let prev = currentPage;
  let next = currentPage;
  let prevButton = null;
  let nextButton = null;

  prev--;
  next++;

  if (currentPage > 1) {
    prevButton = <Link to={`/posts/${prev}`}>
            
    <li className="page-item">
      <button
        class="page-link relative py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-500 focus:shadow-none"
        onClick={() => {
          handleChange(prev);
        }}
      >
        Previous
      </button>
    </li>
  </Link>
  }

  if (currentPage > 1) {
    prevButton = <Link to={`/posts/${prev}`}>
            
    <li className="page-item">
      <button
        class="page-link relative py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-500 focus:shadow-none"
        onClick={() => {
          handleChange(prev);
        }}
      >
        Previous
      </button>
    </li>
  </Link>
  }

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (pageNumbers.length > currentPage) {
    nextButton = <Link to={`/posts/${next}`}>
    <li className="page-item">
      <a
        className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-light hover:text-gray-800 hover:bg-gray-200"
        onClick={() => {
          handleChange(next);
        }}
      >
        Next
      </a>
    </li>
  </Link>
  }

  return (
    <div className="flex justify-center">
      <nav aria-label="Page navigation mx-auto">
        <ul className="flex list-style-none">
          {prevButton}
          {pageNumbers.map((number) => {
            if (currentPage === number) {
              return (
                <Link className="active" to={`/posts/${number}`}>
                  <li key={number} className="page-item active">
                    <a
                      className="page-link relative block py-1.5 px-3.5 border border-primary bg-light outline-none transition-all duration-300 rounded-full text-dark hover:text-white hover:bg-primary shadow-md focus:shadow-md mr-1 ml-1"
                      onClick={() => {
                        handleChange(number);
                      }}
                    >
                      {number}
                    </a>
                  </li>
                </Link>
              );
            } else {
              return (
                <Link className="inactive" to={`/posts/${number}`}>
                  <li key={number} className="page-item">
                    <a
                      className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-light hover:text-dark hover:bg-gray-200 text-light"
                      onClick={() => {
                        handleChange(number);
                      }}
                    >
                      {number}
                    </a>
                  </li>
                </Link>
              );
            }

          })}
          {nextButton}
        </ul>
      </nav>

      <div></div>
    </div>
  );
}

export default PaginationNav;
