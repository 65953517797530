import React, { useEffect, Fragment, useState, componentDidMount } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useSpring, animated, config } from 'react-spring'

function ScrollToTop({ history, children }) {
  
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);