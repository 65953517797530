import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import "../../css/assets.css";
import ScrollEffect from "../../../Hooks/ScrollEffect";
import topBannerImage from "../../images/assets/primary/top-banner.jpg";
import logoBg from "../../images/assets/primary/icon-bg.svg";
//import { ParallaxProvider } from 'react-scroll-parallax';

function TopBanner() {
  return (
    <>
      <div className="relative">
        <section className="container-fluid mx-auto bg-primary relative z-10">
          <div
            className="backgroundImage opacity-50 absolute"
            style={{
              backgroundImage: `url(${logoBg})`,
              backgroundPosition: "left bottom",
              backgroundSize: "contain",
              height: "100%",
              left: "-15%",
              bottom: "0",
            }}
          ></div>

          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-6 sm:col-span-8 sm:col-start-2  text-center col-span-12 mx-auto md:text-left text-center relative lg:px-16 px-8 pt-32 pb-12 text-white md:py-44 lg:pb-44">
              <h1 class="text-2xl leading-tight lg:text-3xl font-heading relative z-10">
                Healthy living starts with a healthy Diet
              </h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <Link to={`/about`} className="">
                <button class="text-dark bg-secondary mt-2 my-auto py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-light hover:text-primary">
                  Read more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
                    fill="none"
                    viewBox="0 0 45 45"
                    width="100"
                    height="100"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
                    />
                  </svg>
                </button>
              </Link>
            </div>

            <div class="md:col-span-6  sm:col-span-3 col-span-12 relative overflow-hidden">
              <div classsName="relative w-100 h-screen top-0">
                <div
                  className="clipMask w-100 top-0 right-0 h-screen backgroundImage absolute"
                  style={{
                    backgroundImage: `url(${topBannerImage})`,
                    backgroundPosition: "right",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default TopBanner;
