import React from 'react';
import TopBanner from '../TopBanner'
import MentalIntro from '../views/MentalHealth/MentalIntro';
import MentalBody from '../views/MentalHealth/MentalBody';


function MentalHealth(){
    return(
        <>
            <TopBanner header="Mental health & diet" />
            <MentalIntro />
            <MentalBody />
        </>
    )
}

export default MentalHealth