import React, { Component } from "react";
import { Notes } from "./Notes";
import { FileUploads } from "./FileUploads";

export class AboutConsumer extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    let nextButton;

    nextButton = (
      <button
        onClick={this.continue}
        class=" disabled:opacity-50 ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 ease-in-out"
      >
        Next step
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block h-8 mt-2 w-10 ml-2"
          fill="none"
          viewBox="0 0 45 45"
          width="100"
          height="100"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
          />
        </svg>
      </button>
    );

    nextButton = (
      <button
        onClick={this.continue}
        class=" disabled:opacity-50 ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 ease-in-out"
      >
        Next step
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block h-8 mt-2 w-10 ml-2"
          fill="none"
          viewBox="0 0 45 45"
          width="100"
          height="100"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
          />
        </svg>
      </button>
    );

    return (
      <div className="w-full">
        <div class="flex space-x-5 justify-between mb-2">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          {nextButton}
        </div>
        <div class="grid grid-cols-2 gap-x-4 bg-light p-4">
          <h2 class="col-span-2 text-xl">About Consumer</h2>
          <h3 class="col-span-2 text-lg text-primary">
            Extra client information
          </h3>
          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <label
              for="exampleFormControlTextarea1"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Disability/Diagnosis/Medical History
            </label>
            <textarea
              onChange={handleChange(
                "Disability_Diagnosis_and_Medical_History"
              )}
              defaultValue={values.Disability_Diagnosis_and_Medical_History}
              class="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Disability and Diagnosis"
            ></textarea>
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <label
              for="exampleFormControlTextarea1"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Consumer Concerns and Goals for Dietary Intervention
            </label>
            <textarea
              onChange={handleChange("consumer_concerns_and_goals")}
              defaultValue={values.consumer_concerns_and_goals}
              class="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Identify goals..."
            ></textarea>
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <label
              for="exampleFormControlTextarea1"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Social Circumstances
            </label>
            <textarea
              onChange={handleChange("Social_circumstances")}
              defaultValue={values.Social_circumstances}
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="What are the social circumstances..."
            ></textarea>
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <label className="mb-1">
              Who should we contact to book appointments?
            </label>
            <select
              onChange={handleChange("who_should_be_contacted")}
              defaultValue={values.who_should_be_contacted}
              class="form-control
                block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              id="grid-state"
            >
              <option>None</option>
              <option>Participant</option>
              <option>Participant representative/carer</option>
              <option>Referrer</option>
            </select>
          </div>
        </div>

        <div class="flex space-x-5 justify-between">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          {nextButton}
        </div>
      </div>
    );
  }
}

export default AboutConsumer;
