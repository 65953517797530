import React from "react";
import FAQ from "../../../Hooks/FAQDropdown";

export default function FAQSection() {
  return (
    <>
      <div className="text-dark bg-white relative z-10">
        <section className="container relative px-4 py-24 z-10 md:py-16 lg:py-18">
          <div className="grid grid-cols-12 gap-8">
            <div className="xl:col-start-3 xl:col-span-8 lg:col-start-2 lg:col-span-10 col-span-12 relative">
              <h4 className="text-2xl text-primary">Diet Link Services</h4>
              <FAQ title={"Gut Health"}>
                <p>
                  Gut symptoms can not only be uncomfortable, they can be
                  debilitating and disruptive to day to day life, and wellbeing.
                  We can support our clients with Food intolerances, Irritable
                  Bowel Syndrome, Diverticular Disease, Crohn’s Disease, Food
                  Allergy, Coeliac Disease, Reflux, Diarrhoea, Constipation.
                </p>
              </FAQ>

              <FAQ title={"Mental Health"}>
                <p>
                  Here at Diet Link we are passionate about increasing mood with
                  food, stabilising energy levels and reducing anxiety with food
                  therapy. The research and evidence is now strong that food and
                  your diet can play a huge part in reducing the severity of
                  mental health disorders and increase wellbeing to live your
                  best life.
                </p>
                <p>
                  Anti-inflammatory diets, identifying food intolerance or
                  allergy, gut health and blood sugar management can all play a
                  part in improving mental illness alongside medical treatments.
                  Even the medications can be more effective when the correct
                  diet is in place for the individual.{" "}
                </p>
                <p>
                  And if medications that you need to manage your mental health,
                  have side effects that effect your weight, or appetite we can
                  help.
                </p>
              </FAQ>
              <FAQ title={"Heart Health and Diabetes"}>
                <p>
                  And if medications that you need to manage your mental health,
                  have side effects that effect your weight, or appetite we can
                  help.
                </p>
              </FAQ>
              <FAQ title={"Eating Disorders and Disorder Eating"}>
                <p>
                  Here at Diet Link we believe Food is Food. No more good and
                  bad food. Improving your relationship with food, and enjoyment
                  in food is key to good health and wellbeing.
                </p>
                <p>
                  If you have a troubled relationship with food, been on too
                  many failed “diets” we are here to help you enjoy the food you
                  eat, feed your body what it deserves and achieve improved
                  health and wellbeing. Body positivity, HAES approach, mindful
                  eating and guidance on How To Eat are strategies we use to
                  feel happy about eating and the foods you eat which in most
                  circumstances leads to a healthy and sustainable weight. No
                  more restriction, no more punishment, no more food rules.{" "}
                </p>
                <p>
                  Diet Link provides Credentialed Eating Disorder (NEDC
                  credentialed) nutrition care. We can see clients who have
                  Anorexia Nervosa, Binge Eating Disorder, ARFID, Bulimia
                  Nervosa and non-specified eating disorders.
                </p>
              </FAQ>
              <FAQ title={"Healthy Eating and Weight Management"}>
                <p>
                  We tailor dietary changes to suit your lifestyle, medical
                  issues and weight history. Developing a healthy relationship
                  with food, and feeding yourself with love is the first step to
                  having a healthy weight. Coaching you towards healthy food
                  choices in a practical and realistic way will keep you there
                  so you never have to worry about weight or food again.
                </p>
                <p>
                  Body positivity, HAES approach, mindful eating and guidance on
                  How To Eat are strategies we use to feel happy about eating
                  and the foods you eat which in most circumstances leads to a
                  healthy and sustainable weight. No more restriction, no more
                  punishment, no more food rules.
                </p>
                <p>
                  For those that have lost their appetite, are at risk of
                  malnutrition and struggle to eat enough whatever the cause, we
                  can provide strategies and support to achieve a weight, and
                  lean muscle mass to gain energy and strength to improve your
                  functioning, and enjoy the things you love to do.
                </p>
              </FAQ>
              <FAQ title={"Disability"}>
                <p>
                  Nutrition is an essential component of maintaining the health
                  and well-being of all individuals. However, for those living
                  with a disability, there may be unique challenges that can
                  affect the ability to prepare meals, consume an adequate
                  amount of food, or meet nutritional requirements. A dietitian
                  is well equipped to support participants in addressing common
                  nutritional issues seen in people with a disability including:
                </p>
                <ul>
                  <li>Malnutrition</li>
                  <li>Gut issues and bowel conditions</li>
                  <li>Food avoidance</li>
                  <li>Depression/anxiety</li>
                  <li>Difficulties in chewing and swallowing</li>
                  <li>Nutritional deficiencies</li>
                  <li>Fatigue</li>
                  <li>Fussy eating</li>
                  <li>Medication-related side effects</li>
                  <li>Management of chronic health conditions</li>
                </ul>
              </FAQ>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
