import React, {useState, useEffect} from 'react';
import axios from "axios";
var FormData = require("form-data");

export function axiosSendEmail(url, formValue, filesStr, dynamic_data) {
  const email = formValue[1].email;
  const subject = formValue[1].subject;
  const body = formValue[0];

  // const fileReader = new FileReader();
  // const data = fileReader.readAsDataURL(filesStr[0]);

  //console.log(dynamic_data);
  //const extra_data = JSON.parse(dynamic_data);
  // const extra_data = [];
  //dynamic_data.map((data, index) => extra_data.push(data));
  // for (var i = 0; filesStr.length >= i; i++) {
  //   extra_data.append("dynamic_data", extra_data);
  // }
  // console.log(extra_data);
  //console.log(dynamic_data);
  // var files = [];
  //  var itemArray = [];

  // for (var i = 0, l = dynamic_data.length; i < l; i++) {
  //   //console.log(filesStr[i]);
  //   itemArray = [];
  //   for (let key in filesStr[i]) {
  //     // var obj = {
  //     //   [key]: filesStr[i][key],
  //     //   //filesStr[i][key]
  //     // };
  //     //var objectDescone = JSON.encode(filesStr[i]);
  //     //itemArray.push({ [key]: filesStr[i][key] });
  //     itemArray.push({ [key]: filesStr[i][key] });
  //     //itemArray.append(`${key}`, filesStr[i][key]);
  //   }

  //   extra_data.push(itemArray);
  // }

  const fileArray = new FormData();

  //console.log(filesStr[0]["file"]);
  if (filesStr !== undefined) {
    for (var i = 0, l = filesStr.length; i < l; i++) {
      fileArray.append("files[]", filesStr[i]);
    }
    console.log("This is undefined");
  }
  // const Files = new FormData();
  //  Files.append("Files", filesStr);
  // filesStr.forEach(function (object, value) {
  //   var objectDescone = JSON.stringify(object);
  //   itemArray = [];
  //   objectDescone.forEach(function (object, value) {
  //     itemArray.push(object);
  //   });
  //   fileArray.push(object);
  // });

  //let filesArray = [];
  //filesArray.append("Files", JSON.stringify(formValue[1].files));
  //const fullNameIN = formValue.firstName +' '+formValue.lastName;
  //const lastNameIN = formValue.lastName;
  //const subjectIN = fullNameIN;

  const headers = {
    //"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //"Content-Type": "multipart/form-data",
    //"Content-Type": "application/octet-stream",
    // Accept: "application/json",
    // "User-Agent": "axios 0.21.1",
    //"Content-Type": "multipart/form-data",
    //Accept: "*/*",
    //"Content-Type": "application/json",
    // accept: "application/json",
    // "Accept-Language": "en-US,en;q=0.8",
    "Content-Type": "multipart/form-data",
    //accept: "application/json",
    //dataType: "json",
  };

  axios
    .post(
      url,
      fileArray,
      {
        params: {
          email: email,
          subject: subject,
          body: body,
          dynamic_data: dynamic_data,
        },
      },
      headers
    )
    .then((response) => console.log(response))
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data); // => the response payload
      }
    });
}