import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from 'react-spring'
import healthyWeight from '../../images/assets/primary/healthy-weight.jpg'
import broccoli from '../../images/assets/primary/broccoli.png'
import pickle from '../../images/assets/primary/pickle.png'
import kiwi from '../../images/assets/primary/kiwi.png'


function NutritionalAdvice(props) {
    
    return (
        <>
        <div className="relative bg-light">



            <section className="container relative mx-auto px-4 z-10 md:py-22 lg:py-32 py-12  text-center lg:text-left">

        
            <div className="flex flex-col-reverse lg:flex-row">
                            
                            <div className="px-0 py-4 md:px-12 md:py-12 md:basis-2/3 basis-1/1">
                        <h3 class="md:text-2xl text-xl text-primary leading-tight">Nutritional advice 
for a healthy weight</h3>
                                <p class="mb-8 relative z-10">
                                However it should also be noted that strong, fit bodies are just as important to what number is on the scales.
                            </p>
                                 
                               
                        </div>
                        <div className="mb-8 lg:mb-0 mx-auto md:px-12 lg:basis-3/4 md:basis-1/2 basis-1/1 relative">
                            
                        <div className="absolute z-1" style={{ maxWidth: '200px', left: '-20px', top: '-100px' }} >
                                <AnimatedAsset duration={15000} rotate={40} minusrotate={20} posx={-8} minusx={8} posy={8} minusy={-15} >
              <img src={broccoli} />
              </AnimatedAsset>
              </div>
                            
              <div className="absolute z-1" style={{ maxWidth: '200px', right: '20px', bottom: '-40px' }} >
                                <AnimatedAsset duration={8000} rotate={20} minusrotate={5} posx={3} minusx={-4} posy={5} minusy={-5} >
              <img src={pickle} />
              </AnimatedAsset>
              </div>
                            <img className="rounded-xl z-8 relative" src={healthyWeight} />

                            <div className="absolute hidden md:block" style={{ maxWidth: '150px', right: '-20px', top: '-60px' }} >
                                <AnimatedAsset duration={20000} rotate={40} minusrotate={-30} posx={3} minusx={-4} posy={5} minusy={-20} >
              <img src={kiwi} />
              </AnimatedAsset>
              </div>
                            
                        </div>
                            </div>
                            
        </section>
    </div>

   

        </>
    );
}

export default NutritionalAdvice;