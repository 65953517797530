import React, { Component } from "react";

export class FormUserDetails extends Component {
  emailData = {
    email: "admin@plaindesignstudio.com",
    subject: "Contact From",
  };

  emailBody = {
    email_to: this.props.values.email_to,
    first_name: this.props.values.firstName,
    last_name: this.props.values.lastName,
    phone: this.props.values.phone,
  };

  submit = (e) => {
    e.preventDefault();
    //Process Form
    this.props.submitEmail([this.emailBody, this.emailData]);
  };

  render() {
    let SubmitButton;

    const { values, handleChange } = this.props;

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      values.lastName &&
      values.firstName &&
      (values.phone.length > 7 || re.test(values.email_to))
    ) {
      SubmitButton = (
        <button
          onClick={this.submit}
          class="ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-light hover:text-primary"
        >
          Submit
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
            fill="none"
            viewBox="0 0 45 45"
            width="100"
            height="100"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
            />
          </svg>
        </button>
      );
    } else {
      SubmitButton = (
        <button
          onClick={this.submit}
          disabled
          class=" disabled:opacity-50 ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 ease-in-out"
        >
          Submit
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block h-8 mt-2 w-10 ml-2"
            fill="none"
            viewBox="0 0 45 45"
            width="100"
            height="100"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
            />
          </svg>
        </button>
      );
    }

    return (
      <div className="w-full">
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="leading-4 text-md mb-1">First name</label>
            <input
              type="text"
              onChange={handleChange("firstName")}
              defaultValue={values.firstName}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="First name"
            />
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label class="leading-4 text-md mb-1">Last name</label>
            <input
              type="text"
              onChange={handleChange("lastName")}
              defaultValue={values.lastName}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="Last name"
            />
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="leading-4 text-md mb-1">Email</label>
            <input
              type="text"
              onChange={handleChange("email_to")}
              defaultValue={values.email_to}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="email@host.com"
            />
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="leading-4 text-md mb-1">Phone</label>
            <input
              type="text"
              onChange={handleChange("phone")}
              defaultValue={values.phone}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="Phone number"
            />
          </div>
        </div>

        {SubmitButton}
      </div>
    );
  }
}

export default FormUserDetails;
