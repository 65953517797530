import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from 'react-spring'
import tomato from '../../images/assets/primary/tomato.png'


function WeightBody(props) {

    return (
        <>
            <div className="relative md:py-24 py-32 md:px-12 px-8">
            <div className="absolute md:d-block d-none z-1" style={{ maxWidth: '120px', left: '-10px', top: '-40px' }} >
                                    <AnimatedAsset duration={15000} rotate={15} minusrotate={25} posx={-8} minusx={8} posy={8} minusy={-15} >
                  <img src={tomato} />
                  </AnimatedAsset>
                </div>
                
          <section className="container mx-auto">
          <div className="grid grid-cols-12 gap-8">
                <div className="
                            col-span-12
                            md:col-span-6
                            lg:col-span-6 relative">
                                    <ScrollEffect speed={-3}>
                        <h2 class="text-4xl mb-12 text-dark leading-tight">
                        Achieve a healthy weight through diet
                                        </h2>
                
                </ScrollEffect>
                
                                    
              </div>
              </div>
            </section>
              <section className="container mx-auto">


                            <div className="grid grid-cols-12 gap-8">
  
                
                <div className="
                            col-span-12
                            md:col-span-6
                            lg:col-span-6 relative">
                                    <ScrollEffect speed={-3}>

                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed quam sed mauris faucibus egestas a quis ligula. Pellentesque interdum, urna sed hendrerit tincidunt, ligula felis congue ipsum, at mattis enim orci quis velit. Vivamus efficitur tincidunt nulla eu maximus. In ac elit nulla. Suspendisse fermentum volutpat cursus. Integer sed consectetur arcu. Cras placerat interdum tellus ac euismod. Praesent sed sapien eu ex condimentum laoreet non id nulla. Donec ut lacinia nunc. Curabitur ac ipsum ac tellus volutpat malesuada. Quisque volutpat mattis malesuada.
                                            </p>
                                    </ScrollEffect>
                                    
              </div>
              
              <div className="
                            col-span-12
                            md:col-span-6
                            lg:col-span-6 relative">
                                    <ScrollEffect speed={-3}>

                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed quam sed mauris faucibus egestas a quis ligula. Pellentesque interdum, urna sed hendrerit tincidunt, ligula felis congue ipsum, at mattis enim orci quis velit. Vivamus efficitur tincidunt nulla eu maximus. In ac elit nulla. Suspendisse fermentum volutpat cursus. Integer sed consectetur arcu. Cras placerat interdum tellus ac euismod. Praesent sed sapien eu ex condimentum laoreet non id nulla. Donec ut lacinia nunc. Curabitur ac ipsum ac tellus volutpat malesuada. Quisque volutpat mattis malesuada.
                                            </p>
                                    </ScrollEffect>
                                    
                                </div>
                                
         
                </div>


                </section>
 
                </div>

            </>
    );
}

export default WeightBody;