import React, { Component } from "react";
import { Link } from "react-router-dom";
import envelope from "../../images/assets/envelope.svg";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

export class Confirm extends Component {
  submit = (e) => {
    e.preventDefault();
    //Process Form
    this.props.submitEmail(this.props.values);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    let emailSet;
    let phoneSet;

    const {
      values: { firstName, lastName, email, phone },
    } = this.props;
    if (email) {
      emailSet = <li>{email}</li>;
    }
    if (phone) {
      phoneSet = <li>{phone}</li>;
    }
    return (
      <div className="">
        <h6 className="text-md text-primary">
          Hi, {firstName} Thank you for <br />
          your request
        </h6>
        <div style={{ width: "100px" }}>
          <AnimatedAsset
            duration={8500}
            rotate={25}
            minusrotate={-25}
            posx={5}
            minusx={-5}
            posy={0}
            minusy={0}
          >
            <img
              className="mb-5 position-absolute"
              width="100%"
              src={envelope}
            />
          </AnimatedAsset>
        </div>
      </div>
    );
  }
}

export default Confirm;
