import React, { useState, useEffect } from "react";

export const Notes = ({ creatNotes }) => {
  const [noteDetails, setNoteDetails] = useState([{ name: "", body: "" }]);
  //const [noteCount, setNoteCount] = useState(0);
  /* The handleChange() function to set a new state for input */

  const handleChange = (e, id) => {
    let value = e.target.value;
    let note_name = e.target.name;
    let index = id;
    //noteDetails[index].name
    // 1. Make a shallow copy of the items
    let items = [...noteDetails];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...noteDetails[index] };
    // 3. Replace the property you're intested in
    if ("name" === note_name) {
      item.name = value;
    } else if ("body" === note_name) {
      item.body = value;
    }
    // 4. Put it back into our array. N.B. we *are* mutating the array here,
    //    but that's why we made a copy first
    items[index] = item;
    // 5. Set the state to our new copy
    setNoteDetails(items);
    creatNotes(items);
  };

  const handleRemoveItem = (e) => {
    //let items = noteDetails;
    var items = noteDetails;

    items.splice(e, 1);
    //setNoteDetails([items]);
    //setNoteDetails((items) => items);
    // if (indexOf > -1) {
    //   // only splice array when item is found
    //   let newItems = noteDetails.splice(indexOf, 1); // 2nd parameter means remove one item only
    //   noteDetails(newItems);
    // }
    setNoteDetails((noteDetails) => [...items]);
  };

  const addNoteForm = () => {
    //for (var i = 0; i <= noteDetails.length; i++) {
    //setNoteCount((noteCount) => noteCount + 1);
    let allowNewNote = true;

    for (var i = 0; i < noteDetails.length; i++) {
      let name = noteDetails[i].name;
      let body = noteDetails[i].body;

      if (body === "" || name === "") {
        allowNewNote = false;
      } else {
      }
    }
    if (allowNewNote === true) {
      setNoteDetails((noteDetails) => [...noteDetails, { name: "", body: "" }]);
    }
    //noteDetails.push([{ name: "" }]);

    //}
  };

  const formArray = [];

  for (var i = 0; i < noteDetails.length; i++) {
    let index = i;
    formArray.push(
      <div class="form-group mb-3 col-span-2 sm:col-span-2">
        <input
          name="name"
          type="text"
          value={noteDetails[i].name}
          onChange={(e, id) => handleChange(e, index)}
          className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0 mb-2
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
          placeholder="Note Title"
        />

        <textarea
          value={noteDetails[i].body}
          placeholder="Note Text"
          name="body"
          onChange={(e, id) => handleChange(e, index)}
          class="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        mb-4
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
          id="exampleFormControlTextarea1"
          rows="3"
          placeholder="Disability and Diagnosis"
        ></textarea>
        <div class="flex space-x-5 justify-end mb-8">
          <button
            onClick={(e) => handleRemoveItem(index)}
            class="group inline-block border border-red bg-none py-2 px-4 text-red leading-tight rounded-full hover:bg-red hover:text-light focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            Remove Note X
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <h3 class="col-span-2 text-lg text-primary mb-2 mt-3">Extra Notes</h3>
      <div class="form-group mb-4 col-span-2 sm:col-span-2">{formArray}</div>
      <div class="flex space-x-5 justify-center mb-8">
        <button
          onClick={addNoteForm}
          class="group inline-block border border-primary bg-primary py-2 px-4 text-light text-base leading-tight rounded-full hover:bg-light hover:text-primary hover:border-primary focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Add New Note +
        </button>
      </div>
    </>
  );
};

export default Notes;
