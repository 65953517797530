import React, { Component } from "react";
import { Link } from "react-router-dom";
import envelope from "../../images/assets/envelope.svg";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

export class Confirm extends Component {
  submit = (e) => {
    e.preventDefault();
    //Process Form
    this.props.submitEmail(this.props.values);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    // let emailSet;
    // let phoneSet;

    // const { values: { firstName, lastName, email, phone} } = this.props;
    // if (email) {
    //     emailSet = <li>{email}</li>
    // }
    // if (phone) {
    //   phoneSet = <li>{phone}</li>
    // }
    return (
      <div className="">
        <h2 className="text-center text-primary">
          Thank you for <br />
          your referral
        </h2>
        <div className="mx-auto" style={{ width: "100px" }}>
          <AnimatedAsset
            duration={8500}
            rotate={25}
            minusrotate={-25}
            posx={5}
            minusx={-5}
            posy={0}
            minusy={0}
          >
            <img
              className="mb-5 position-absolute mx-auto"
              width="100%"
              src={envelope}
            />
          </AnimatedAsset>
        </div>
        <div className="w-100 pt-4 justify-center flex">
          <Link to="/Posts/1">
            <button
              type="button"
              class="group justify-items-center inline-block border border-secondary bg-secondary py-2 px-4 text-dark text-base leading-tight rounded-full hover:bg-light hover:text-primary hover:border-primary focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            >
              Continue to Articles
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Confirm;
