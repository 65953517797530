import React, { Component, useState } from "react";
import { Notes } from "./Notes";
import { FileUploads } from "./FileUploads";

export class UploadSection extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  selectsRange = (e) => {
    this.props.setNewDateRange(e);
    //console.log("date");
  };

  addFileArray = (file) => {
    this.props.addFile(file);
  };

  creatNotes = (e) => {
    this.props.addDynamicData(e);
  };

  removeFile = (index) => {
    const { fileArray } = this.state;
    console.log(fileArray[index].name);
    if (index !== -1) {
      fileArray.splice(index, 1);
      this.setState({ fileArray: fileArray });
    }
    this.props.addFile(fileArray);
  };

  state = {
    error: "",
    class: "",
  };

  showErrors = () => {
    this.setState({
      error: "this is required",
      class: "border-2 border-red",
    });
  };

  render() {
    const { values, handleChange } = this.props;

    let nextButton;

    nextButton = (
      <button
        onClick={this.continue}
        class="ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-light hover:text-primary"
      >
        Next Step
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
          fill="none"
          viewBox="0 0 45 45"
          width="100"
          height="100"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
          />
        </svg>
      </button>
    );

    return (
      <div className="w-full">
        <div class="flex space-x-5 justify-between mb-1">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          {nextButton}
        </div>
        <div class="grid grid-cols-2 gap-x-4 bg-light p-4">
          <h2 class="col-span-2 text-xl">Diet Plan</h2>
          <h3 class="col-span-2 text-lg text-primary">
            Clients funding information
          </h3>
          <FileUploads FileArray={this.addFileArray} />
          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <Notes creatNotes={this.creatNotes} />
          </div>
        </div>

        <div class="flex space-x-5 justify-between">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          {nextButton}
        </div>
      </div>
    );
  }
}

export default UploadSection;
