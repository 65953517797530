import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { Link } from "react-router-dom";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import Avacado from "../../images/assets/primary/avacado.png";

export default function HomeIntro() {
  return (
    <>
      <div className="text-dark bg-light relative z-10">
        <section className="container relative mx-auto px-4 py-24 z-10 md:py-16 lg:py-18">
          <div className="grid grid-cols-12 gap-8">
            <div className="lg:col-span-7 lg:col-start-3 mx-auto col-span-12 relative text-center">
              <h4 class="text-3xl text-primary leading-tight lg:text-3xl font-heading relative z-10">
                This is about us
              </h4>
              <p class="mb-8 relative z-10">
                ready for change? We can help you optimise your diet
                specifically tailored to your health needs and goals for you to
                get the best out of life
              </p>
              <Link to={`/about`} className="">
                <button class="text-dark bg-secondary mt-8 my-auto py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-primary hover:text-light">
                  Read more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
                    fill="none"
                    viewBox="0 0 45 45"
                    width="100"
                    height="100"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </section>
        <div
          className="absolute z-8"
          style={{ maxWidth: "200px", left: "-50px", bottom: "-60px" }}
        >
          <AnimatedAsset
            duration={20000}
            rotate={40}
            minusrotate={-30}
            posx={3}
            minusx={-4}
            posy={5}
            minusy={-20}
          >
            <img src={Avacado} />
          </AnimatedAsset>
        </div>
      </div>
    </>
  );
}
