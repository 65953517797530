import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from "react-spring";
import { Link } from "react-router-dom";

function Priceplan(props) {
  return (
    <>
      <div className="relative bg-light">
        <section className="container relative px-4 py-24 z-10 md:py-16 lg:py-18">
          <div className="grid grid-cols-12 lg:gap-12 justify-center">
            <div className="col-span-12 text-center">
              <h4 className="text-3xl text-primary">Pricing</h4>
            </div>
            <div className="md:col-span-8 md:col-start-3 lg:col-span-6 col-span-12 mb-8">
              <div className="bg-gradient-to-r from-primary to-primaryDark w-100 text-white rounded-xl p-8 lg:p-8">
                <h4 class="text-light mb-12">NDIS Fee Structure</h4>

                <div class="flex flex-row text-md">
                  <div class="basis-2/3">
                    <p className="mb-1">Core</p>
                    <p>Improved Daily Living</p>
                  </div>
                  <div class="basis-1/3 text-right">
                    <p>$193.99ph</p>
                  </div>
                </div>

                <hr class="my-4 h-px bg-white border-0" />

                <div class="flex flex-row text-md">
                  <div class="basis-2/3">
                    <p className="mb-1">Capacity Building</p>
                    <p>Improved Daily Living</p>
                  </div>
                  <div class="basis-1/3 text-right">
                    <p>$193.99ph</p>
                  </div>
                </div>

                <hr class="my-4 h-px bg-white border-0" />

                <div class="flex flex-row text-md">
                  <div class="basis-2/3">
                    <p className="mb-1">Capacity Building</p>
                    <p>Improved Daily Living</p>
                  </div>
                  <div class="basis-1/3 text-right">
                    <p>$193.99ph</p>
                  </div>
                </div>
                <Link to={`/contact`}>
                  <button class="text-dark bg-secondary mt-8 my-auto py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                    Start Now
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
                      fill="none"
                      viewBox="0 0 45 45"
                      width="100"
                      height="100"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            </div>

            <div className="md:col-span-8 md:col-start-3 lg:col-span-6 col-span-12">
              <div className="bg-gradient-to-r from-light to-lightdark w-100 text-dark border border-primary rounded-xl p-8 lg:p-8">
                <h4 class="text-primary mb-12">Private Fee structure</h4>

                <div class="flex flex-row text-md">
                  <div class="basis-2/3">
                    <p className="mb-1">Initial Consultation</p>
                    <p className="font-weight-light">(45 - 60mins)</p>
                  </div>
                  <div class="basis-1/3 text-right text-primary">
                    <h6>$139</h6>
                  </div>
                </div>

                <hr class="my-4 h-px bg-primary border-0" />

                <div class="flex flex-row text-md">
                  <div class="basis-2/3">
                    <p className="mb-1">Review Consultation</p>
                    <p className="font-weight-light">(30mins)</p>
                  </div>
                  <div class="basis-1/3 text-right text-primary">
                    <h6>$79</h6>
                  </div>
                </div>

                <hr class="my-4 h-px bg-primary border-0" />

                <div class="flex flex-row text-md">
                  <div class="basis-2/3">
                    <p className="mb-1">Extended Review Consultation</p>
                    <p className="font-weight-light">(45-60mins)</p>
                  </div>
                  <div class="basis-1/3 text-right text-primary">
                    <h6>$139</h6>
                  </div>
                </div>
                <hr class="mt-4 mb-8 h-px bg-primary border-0" />

                <p>
                  All medicare claims will cover $55.10 of your scheduled
                  appointment fee. You will be required to provide your initial
                  GPMP to access this discount.
                </p>
                <p className="text-darklt">
                  Check you private health insurance plan to see if you are
                  eligible for private health rebates.{" "}
                </p>

                <Link to={`/contact`}>
                  <button class="text-dark bg-secondary mt-8 my-auto py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-primary hover:text-light">
                    Start Today
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
                      fill="none"
                      viewBox="0 0 45 45"
                      width="100"
                      height="100"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Priceplan;
