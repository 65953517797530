import React, { useState} from 'react';
import { useSpring, animated } from '@react-spring/web'

function Iconhover(props) {

    const [hover, hovertoggle] = useState(true)
  
    const { x } = useSpring({
    from: {
      x: hover ? 0 : 1,
      
    },
    x: hover ? 1 : 0,
    config: { duration: 380,    tension: 300,
      friction: 10,
    },
  })

    return (
        <>
<animated.div onMouseEnter={() => hovertoggle(!hover)}
              style={{
                rotate: x.to({
                   range: [0, 0.5, 0.9,  1],
                  output: [0, -30, 10, 0],
                }),
                scale: x.to({
                  range: [0, 0.5, 1],
                  output: [1, 1.2, 1],
                }),
                width: '50px', height: '50px'
              }}
              class="mr-6 flex justify-center p-2">
            {props.children}
            </animated.div>
        </>
    );

}
export default Iconhover;