import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DatePickerDOB(props) {


  const [condob, setDate] = useState();

  //const handleCalendarClose = () => props.handleChange('date');
  const handleCalendarOpen = () => console.log("Calendar opened");

    //Handle Date
  let selectHandleDate = () => {
    props.selectDate(condob);
  };
  
 /* <div className="child">
  <p>Child</p>
  <DatePicker selected={props.date} onChange={props.handleChange('date')} />
</div> */
  //const handleCalendarChange = () => props.handleChange('date');
  return (
    <div className="child">
              <label className="mb-1">
              Date of birth
              </label>
      <DatePicker
        className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
             selected={condob}
             onChange={(condob) => setDate(condob)}
             onCalendarClose={selectHandleDate}
        onCalendarOpen={handleCalendarOpen()}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        />
      </div>
  );

}

export default DatePickerDOB
