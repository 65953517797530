import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { useSpring, animated, config, easings } from 'react-spring'
import athlete from '../../images/assets/primary/athlete.jpg'
import chilli from '../../images/assets/primary/chili.png'
import radish from '../../images/assets/primary/radish.png'
import sushi from '../../images/assets/primary/sushi.png'
import apple from '../../images/assets/primary/apple.png'

function AthleteIntro(props) {

    return (
        <>
            <div className="relative bg-content">
            <div className="absolute md:d-block d-none z-1" style={{ maxWidth: '150px', left: '-50px', top: '-40px' }} >
                                    <AnimatedAsset duration={15000} rotate={15} minusrotate={25} posx={-8} minusx={8} posy={8} minusy={-15} >
                  <img src={sushi} />
                  </AnimatedAsset>
                </div>
                
              <section className="container-fluid mx-auto">
                <div className="">

                            <div className="grid grid-cols-12 gap-8">
                            <div className="
                            col-span-12
                            md:col-span-8
                            md:col-start-1
                            lg:col-span-7 lg:col-start-2 py-32 md:py-32 md:px-12 px-8 relative">
                                    <ScrollEffect speed={-3}>
                        <h2 class="text-4xl mb-5 text-primary leading-tight">
                      We are dietitians for competitive athletes
                    </h2>
                                        <p className="text-dark">
                                        Food is our bodies fuel. Performance and increased strength, and agility relies on what you put in and when. Our dietitians are qualified Sports Dietitians with experience with meal planning for competitive athletes to helping those wanting to get the best out of their sport and fitness aspirations. Exercise is a key component of good health and improved wellbeing, so find out how certain foods and their timing to maximize your efforts, and enjoy the feeling of going harder, longer, faster or simply easier during your workouts, training or leisure activities.                                            </p>
                                    </ScrollEffect>
                                    
                                </div>
                                
                            <div class="md:col-span-4
                     
                                sm:col-span-2
                                col-span-4
                                relative overflow-hidden">
              <div classsName="relative w-100 h-screen top-0">
              <div
            
            className="clipMask w-100 top-0 right-0 bg-primary h-screen backgroundImage absolute"
                                    ></div>

<div className="absolute md:d-block d-none z-1 h-64" style={{ right: '-50px', bottom: '40%' }} >
<ScrollEffect speed={-8}>
                                    <img className="rounded-xl z-8 relative h-100" src={athlete} />
  </ScrollEffect>
                            </div>
                                    
                                 
              </div>
            </div>
                </div>

        </div>
                </section>
                <div className="absolute md:d-block d-none z-1" style={{ maxWidth: '100px', right: '10px', bottom: '-40px' }} >
                                    <AnimatedAsset duration={15000} rotate={30} minusrotate={35} posx={-8} minusx={8} posy={8} minusy={-15} >
                  <img src={chilli} />
                  </AnimatedAsset>
                            </div>
                </div>

            </>
    );
}

export default AthleteIntro;