import React from "react";
import { Reveal } from "../Hooks/Reveal"
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import ScrollEffect from "../Hooks/ScrollEffect";
import './css/assets.css'
import ScrollBlur from "../Hooks/ScrollBlur";
//import { ParallaxProvider } from 'react-scroll-parallax';

function TopBanner(props) {

  return (
    <>
      


      <div className="container-fluid top-0 relative bg-light z-8 overflow-hidden">
        <div className="relative">
         
                    <div
            
            className="w-100 hidden top-0 backgroundImage absolute bg-gradient-to-t from-dark"
            style={{
              height: "75vh",
            }}
          ></div>
          <div className="w-100 relative">
            <div
              class="flex justify-center items-center"
              style={{ height: "50vh", minHeight: "400px" }}
            >

              <div class="pt-28 px-8">
              <ScrollEffect speed={-5} startScroll={-400} endScroll={380}>
         
              <h6 class="text-primary text-sm font-light">{props.topHeader}</h6>
                  <h1 className="relativez-50 text-dark leading-tight font-heading">
                    {props.header}
                  </h1>
            
                  </ScrollEffect>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      
      <div className="h-50 w-full overflow-clip hidden">
      
        <div className="relative top-0 bg-light w-100 py-12 h-50">

          <div className="relative container px-4 flex flex-wrap md:flex-row-reverse">

           
        <div className="w-full md:w-8/12 lg:w-8/12 mx-auto text-center relative">



              <div className="relative text-white md:my-24 my-8">

                <div className="rounded-xl p-8">
            
                  <h6 class="text-primary">{props.topHeader}</h6>
                  <h1 className="relativez-50 text-dark md:text-4xl lg:text-4xl xl:text-5xl leading-tight font-heading">
                    {props.header}
                          <div className="hidden relative" width="100px">
                <svg width="100%" height="100%" viewBox="0 0 2000 100" preserveAspectRatio="xMaxYMax" >
<g>
	<path className='fill-primary' d="M35.7,42.3C159.7,20.2,285.4,8.6,411.1,3.6C505.5,0.1,600.2,0.5,694.4,8c30.8,2.3,63.5,5.8,94.1,9.7
		c25.4,4,21.6,40.8-4.5,39.1C629.7,41.9,474,37.6,319,45.3C230,49.5,140.5,57,52.1,69c-3.9,0.5-7.8,1.1-11.5,1.7
		C22.2,72.8,17.3,46.7,35.7,42.3L35.7,42.3z"/>
</g>
                  </svg>
                  </div>
                    </h1>
                 
          </div>
   
          </div>
      
        </div>
      </div>
      </div>
      </div>
      </>
  );
}

export default TopBanner;
