import React from 'react';
import { useParallax } from "react-scroll-parallax";
// UPDATE this path to your copy of the hook!
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
// import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';
export function ScrollEffect(props){


    const scrollChange = useParallax({
        speed: props.speed,
        rotate: [props.rotateFrom, props.rotateTo],
        translateY: [props.YFrom, props.YTo],
        translateX: [props.XFrom, props.XTo],
        endScroll: props.endScroll,
        startScroll: props.startScroll,
    });

    return (
        <>

            <div ref={scrollChange.ref}>
            {props.children}
            </div>
        </>
    );

}

export default ScrollEffect;
