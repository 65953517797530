import React, { Component } from "react";
import DatePickerDOB from "./DatePickerDOB";

export class FormUserDetails extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  selectDate = (e) => {
    this.props.setDOB(e);
  };

  //const { contactMethodStyle } = this.props.contactmethod(e);

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  state = {
    error: "",
    class: "",
  };

  showErrors = () => {
    this.setState({
      error: "this is required",
      class: "border-2 border-red",
    });
  };

  render() {
    const { values, handleChange } = this.props;

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let nextButton;

    if (
      (values.consumer_first_name &&
        values.consumer_last_name &&
        values.contact_phone.length > 7) ||
      (re.test(values.contact_email) &&
        values.referrer_first_name &&
        values.referrer_last_name)
    ) {
      nextButton = (
        <button
          onClick={this.continue}
          class="ml-auto text-dark bg-secondary mt-2 py-2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-light hover:text-primary"
        >
          Next Step
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block h-8 mt-2 w-10 ml-2 group-hover:translate-x-2 transition transition-500"
            fill="none"
            viewBox="0 0 45 45"
            width="100"
            height="100"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
            />
          </svg>
        </button>
      );
    } else {
      nextButton = (
        <button
          onClick={this.showErrors}
          class=" disabled:opacity-50 ml-auto text-dark bg-light mt-2 py-0.2 font-bold pl-8 pr-3 group inline-block leading-tight focus:outline-none focus:ring-0 ease-in-out"
        >
          Next step
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block h-8 mt-2 w-10 ml-2"
            fill="none"
            viewBox="0 0 45 45"
            width="100"
            height="100"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M3.7,3.5l12.8,12.8 M16.4,16.2L3.7,29"
            />
          </svg>
        </button>
      );
    }

    return (
      <div className="w-full">
        <div class="flex space-x-5 justify-between mb-2">{nextButton}</div>
        <div class="grid grid-cols-2 gap-x-4 bg-light p-4">
          <h2 class="col-span-2 text-xl">Consumer information</h2>
          <h3 class="col-span-2 text-lg text-primary">Personal Information</h3>
          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">First name *</label>
            <input
              type="text"
              onChange={handleChange("consumer_first_name")}
              defaultValue={values.consumer_first_name}
              className={`${
                values.consumer_first_name ? "" : this.state.class
              } form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease -in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              placeholder="First name"
            />
            {!values.consumer_first_name && this.state.error ? (
              <small className="text-red">This is required</small>
            ) : (
              ""
            )}
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">Last name</label>
            <input
              type="text"
              required
              onChange={handleChange("consumer_last_name")}
              defaultValue={values.consumer_last_name}
              className={`${
                values.consumer_last_name ? "" : this.state.class
              } form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              placeholder="Last name"
              aria-describedby="passwordHelp"
            />
            {!values.consumer_last_name && this.state.error ? (
              <small className="text-red">This is required</small>
            ) : (
              ""
            )}
          </div>

          <DatePickerDOB selectDate={this.selectDate} />
        </div>

        <div class="grid grid-cols-2  mb-4 gap-x-4 bg-light p-4">
          <h3 class="col-span-2 text-lg text-primary">Address</h3>
          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <label className="mb-1">Street Address</label>
            <input
              type="text"
              onChange={handleChange("address")}
              defaultValue={values.address}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="House number and street"
            />
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <input
              type="text"
              onChange={handleChange("address2")}
              defaultValue={values.address2}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="suburb"
            />
          </div>
          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">State</label>
            <input
              type="text"
              onChange={handleChange("state")}
              defaultValue={values.state}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="Address"
            />
          </div>
          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label class="mb-1">Area code</label>
            <input
              type="text"
              onChange={handleChange("areacode")}
              defaultValue={values.areacode}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="Area code"
            />
          </div>
        </div>

        <div class="grid grid-cols-2 mb-4 gap-x-4 bg-light p-4">
          <div class="form-group mb-4 col-span-2 sm:col-span-2">
            <h2 class="col-span-2 text-xl">Contact information</h2>
            <select
              onChange={handleChange("contact_method")}
              defaultValue={values.contact_method}
              class="form-control
                block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              id="grid-state"
            >
              <option>Client</option>
              <option>Carer</option>
            </select>
          </div>
          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">{values.contact_method} Name</label>
            <input
              type="text"
              onChange={handleChange("contact_name")}
              defaultValue={values.contact_name}
              className={`${
                values.contact_name ? "" : this.state.class
              } form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              placeholder="Full name"
            />
            {!values.contact_name && this.state.error ? (
              <small className="text-red">This is a required field</small>
            ) : (
              ""
            )}
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">{values.contact_method} Email</label>
            <input
              type="text"
              onChange={handleChange("contact_email")}
              defaultValue={values.contact_email}
              className={`${
                values.contact_email ? "" : this.state.class
              } form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              placeholder="email@host.com"
            />
            {!values.contact_email && this.state.error ? (
              <small className="text-red">
                This must be a correct email address
              </small>
            ) : (
              ""
            )}
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">{values.contact_method} Phone</label>
            <input
              type="text"
              onChange={handleChange("contact_phone")}
              defaultValue={values.contact_phone}
              className={`${
                values.contact_phone ? "" : this.state.class
              } form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              placeholder="Phone number"
            />
            {!values.contact_phone && this.state.error ? (
              <small className="text-red">
                This must filled with be a phone number
              </small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div class="grid grid-cols-2 mb-4 gap-x-4 bg-light p-4">
          <h2 class="col-span-2 text-xl">Referrer information</h2>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">First name</label>
            <input
              type="text"
              onChange={handleChange("referrer_first_name")}
              defaultValue={values.referrer_first_name}
              className={`
              ${values.referrer_first_name ? "" : this.state.class}
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              placeholder="First name"
            />
            {!values.referrer_first_name && this.state.error ? (
              <small className="text-red">This is required</small>
            ) : (
              ""
            )}
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">Last name</label>
            <input
              type="text"
              onChange={handleChange("referrer_last_name")}
              defaultValue={values.referrer_last_name}
              className={`
              ${values.referrer_last_name ? "" : this.state.class}
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none`}
              placeholder="Last name"
            />
            {!values.referrer_last_name && this.state.error ? (
              <small className="text-red">This is required</small>
            ) : (
              ""
            )}
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">Email</label>
            <input
              type="text"
              onChange={handleChange("referrer_email")}
              defaultValue={values.referrer_email}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="email@host.com"
            />
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">Phone</label>
            <input
              type="text"
              onChange={handleChange("referrer_phone")}
              defaultValue={values.referrer_phone}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="Phone number"
            />
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">NDIS participant no.</label>
            <input
              type="text"
              onChange={handleChange("ndis_number")}
              defaultValue={values.ndis_number}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="NDIS Number"
            />
          </div>

          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">Referrer number</label>
            <input
              type="text"
              onChange={handleChange("refnumber")}
              defaultValue={values.refnumber}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="Referrer number"
            />
          </div>
          <div class="form-group mb-4 col-span-2 sm:col-span-1">
            <label className="mb-1">Relationship with consumer</label>
            <input
              type="text"
              onChange={handleChange("relationship")}
              defaultValue={values.relationship}
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-primary focus:outline-none"
              placeholder="Speech pathologist"
            />
          </div>
        </div>
        <div class="flex space-x-5 justify-between">{nextButton}</div>
      </div>
    );
  }
}

export default FormUserDetails;
