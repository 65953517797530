import React, {useState} from 'react';
import Pagination from '../views/Casestudy/GetPosts';
import { useAxiosGet } from '../../Hooks/httpRequest';
import PaginationNav from '../../Hooks/PaginationNav';
import { useParams } from 'react-router-dom';
import TopBanner from '../TopBanner'
//import HelloWorld from '../HelloWorld'

function Cases(){ 


  const { pagenumber } = useParams()
  
    const [page, setPage] = React.useState(pagenumber);

    function handleChange(newValue) {
      setPage(newValue);
    }


    const svgStyleTop = {
      position: 'absolute',
      color: "white",
      width: '100%',
      top: '-1px',
    };
  
  
    const itemsPerPage = 10
    const [currentPage, setCurrentPage] = useState(1);
  
  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/case_study?page=${page}`
  const totalQty = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/case_study/v1/getpostqty`

  let posts = useAxiosGet(url)
  let totalItems = useAxiosGet(totalQty)

    let content = null

    if(posts.error){
        content = <div class="pt-24 flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    }

    if(posts.loading){
        content = <div class="pt-24 flex items-center justify-center space-x-2">
        <div class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full" role="status">
          <span class="visually-hidden">L</span>
        </div>
      </div>
    }

    //Get current post

    //const paginate = (pageNumber) => setCurrentPage(pageNumber)


    if(posts.data){
        content = <Pagination items={posts} loading={posts.loading} currentPage={posts.currentPage} itemsPerPage={posts.itemsPerPage} />
        }
    
    return(

      <div className="bg-white">
    <TopBanner header="Case Studies" />
        <div className="relative">
      <svg style={svgStyleTop} className="left-0" width="100%" viewBox="0 0 935 80" xmlns="http://www.w3.org/2000/svg">
          <polygon className="fill-light " points="1241.7,0 0,77.1 0,0 "/>
        </svg>
        </div>

          {content}
         
        <PaginationNav onChange={handleChange} totalItems={totalItems.data} itemsPerPage={itemsPerPage} currentPage={pagenumber} />
        </div>
    )
}

export default Cases